import { Modal,Button } from 'antd';

type ConfirmationModalProps = {
  visible: boolean;
  handleOk: (data: any) => void;
  handleCancel: (data: any) => void;
  title: string;
  content?: string;
  danger?: boolean;
};

const RemoveConfirmationModal = (props: ConfirmationModalProps) => (
  <Modal
  centered={true}
    title={props.title}
    onCancel={props.handleCancel}
    style={{textAlign:'center'}}
    width='20%'
    onOk={props.handleOk}
    footer={null}
    closable={false}
    visible={props.visible}>
    {props.content && <p>{props.content}</p>}
    <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
    <Button type="primary" onClick={props.handleOk}>Yes</Button>
    <Button type="primary" danger onClick={props.handleCancel} style={{ marginLeft: '10px' }}>No</Button>
  </div>
  </Modal>
);

export default RemoveConfirmationModal;
