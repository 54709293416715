import { Col, Row, Typography, message } from 'antd';
import React, { useEffect, useState } from 'react';

import EditSmsTemplateModal from '../../../modals/templates/sms/update';
import { Helmet } from 'react-helmet';
import { IsAdminProp } from '../../../types/props/isAdmin.props';
import SecureComponent from '../../../components/authorization';
import { SmsTemplate } from '../../../types/data/smsTemplate.type';
import { TableType } from '../../../enums/table-type.enum';
import { TemplateService } from '../../../service/template.service';
import TemplatesComponent from '../../../components/templates/all-templates';

const SmsTemplatesPage: React.FC<IsAdminProp> = () => {
  const [smsTemplates, setSmsTemplates] = useState([]);
  const [loading, setLoading] = useState(false);
  const [editSmsTemplateVisible, setEditSmsTemplateVisible] = useState(false);
  const [selectedSmsTemplate, setSelectedSmsTemplate] = useState<SmsTemplate>();

  const getAllSmsTemplates = () => {
    setLoading(true);
    TemplateService.getAllSmsTemplates()
      .then(smsTemplates => {
        setSmsTemplates(smsTemplates);
      })
      .catch(err => {
        console.log('Error while fetching sms templates :: ', err);
        message.error('Failed to fetch sms templates, please try again later');
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    getAllSmsTemplates();
  }, []);

  const handleTemplatesEditClick = (template: SmsTemplate) => {
    setEditSmsTemplateVisible(true);
    setSelectedSmsTemplate(template);
  };

  const handleSmsTemplateCancel = () => {
    setEditSmsTemplateVisible(false);
    setSelectedSmsTemplate(undefined);
  };

  const updateSmsTemplate = (template: SmsTemplate) => {
    setLoading(true);
    TemplateService.updateSmsTemplate(template)
      .then(() => {
        message.success('SMS template updated successfully');
      })
      .catch(err => {
        console.log('Error while updating SMS template :: ', err);

        message.error(
          'Failed to updating SMS template, please try again later'
        );
      })
      .finally(() => {
        setLoading(false);
        setEditSmsTemplateVisible(false);
        setSelectedSmsTemplate(undefined);
        getAllSmsTemplates();
      });
  };

  return (
    <>
      <Helmet>
        <title>
          SMS Templates | Quoting Tool SA Dashboard | Elite Insurance Partners
        </title>
        <meta
          name='description'
          content={`Elite Insurance Partners | Quoting Tool SA Dashboard - SMS Templates`}
        />
      </Helmet>
      <Row>
        <Col xs={24} className='space-top-10'>
          <Typography.Title level={3}>SMS Templates</Typography.Title>
        </Col>
        <Col xs={24} className='space-top-10'>
          <TemplatesComponent
            loading={loading}
            templates={smsTemplates}
            type={TableType.SMS_TEMPLATES}
            handleEdit={handleTemplatesEditClick}
          />
        </Col>

        <EditSmsTemplateModal
          visible={editSmsTemplateVisible}
          data={selectedSmsTemplate as SmsTemplate}
          loading={loading}
          handleCancel={handleSmsTemplateCancel}
          handleOk={updateSmsTemplate}
        />
      </Row>
    </>
  );
};

export default SecureComponent(SmsTemplatesPage, false);
