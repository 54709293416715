import { AccountInfo, IPublicClientApplication } from '@azure/msal-browser';

import Cookie from 'js-cookie';
import { StringConstants } from '../constants/string.constants';
import find from 'lodash.find';
import isEmpty from 'lodash.isempty';
import jwt from 'jsonwebtoken';
import moment from 'moment';
import orderBy from 'lodash.orderby';

type JwkKey = {
  e: string;
  issuer: string;
  kid: string;
  n: string;
  kty: string;
  x5c: string[];
  x5t: string;
};

type Jwk = {
  keys: JwkKey[];
};

/**
 * Fetch JWK from azure ad
 * @returns {Jwk} jwk
 */
const getJWK = async (): Promise<Jwk> => {
  const res = await fetch(process.env.REACT_APP_AZURE_AD_DOMAIN as string);
  const jwk = await res.json();
  return jwk;
};

/**
 * Save user and jwt in cookie
 * @param {string} jwtToken - jwt token
 * @returns {void} - returns nothing
 */
export const saveCookie = (jwtToken: string): void => {
  Cookie.set(StringConstants.COOKIE.USER, jwt.decode(jwtToken) as string);
  Cookie.set(StringConstants.COOKIE.JWT, jwtToken);
};

/**
 * Delete token in cookie
 * @returns {void} - returns nothing
 */
export const deleteCookie = (): void => {
  Cookie.remove(StringConstants.COOKIE.USER);
  Cookie.remove(StringConstants.COOKIE.JWT);
};

/**
 * Get matching KID from jwk
 * @param {object[]} jwkKeys - jwk keys
 * @param {string} decodedToken - decoded token
 * @returns {JwkKey} - returns matched JWK key
 */
const getKIDMatchingKey = (jwkKeys: any, decodedToken: any): JwkKey => {
  return find(jwkKeys, key => key.kid === decodedToken.header.kid);
};
/**
 * Verify token
 * @param {string} token - token to be verified
 * @returns {boolean} - returns true, if token  is valid. Else false
 */
export const verifyToken = async (token: string): Promise<boolean> => {
  if (token) {
    const decodedToken = jwt.decode(token, { complete: true });
    const jwk: Jwk = await getJWK();
    const kidMatchingKey: JwkKey = getKIDMatchingKey(jwk.keys, decodedToken);

    // if matching key, prepare certificate and verify
    if (kidMatchingKey) {
      let cert: string = kidMatchingKey.x5c[0];
      const matchedCert: RegExpMatchArray | null = cert.match(/.{1,64}/g);

      if (matchedCert) {
        cert = matchedCert.join('\n');
        cert = `-----BEGIN CERTIFICATE-----\n${cert}\n-----END CERTIFICATE-----\n`;

        try {
          jwt.verify(token, cert);
          return true;
        } catch (error) {
          console.error(error);
          return false;
        }
      }
    }
  }
  return false;
};

/**
 * Get user token from cookie
 * @returns {string | undefined | false} - String if token is valid, undefined if no token is set, false if token is invalid
 */
export const getTokenForBrowser = async (): Promise<
  string | undefined | false
> => {
  const token: string = Cookie.get(StringConstants.COOKIE.JWT) as string;
  const validToken = await verifyToken(token);
  if (validToken) {
    return Cookie.get(StringConstants.COOKIE.USER);
  } else {
    return false;
  }
};

/**
 * Get accounts that are signed in
 * @param {AccountInf[]} accounts - list of active accounts
 * @returns {AccountInfo | undefined} - AccountInfo -> if active signed in account is present, undefined if no account found
 */
export const getActiveSignedInAccount = (
  accounts: AccountInfo[]
): AccountInfo | undefined => {
  const unexpiredAccounts = accounts.filter((account: AccountInfo) => {
    if (account && account.idTokenClaims && account.idTokenClaims.exp)
      return moment.unix(account.idTokenClaims.exp).isAfter(moment());
  });
  if (unexpiredAccounts.length === 0) return undefined;
  else if (unexpiredAccounts.length === 1) return unexpiredAccounts[0];
  else return orderBy(unexpiredAccounts, 'idTokenClaims.iat', 'desc')[0];
};

/**
 * Parse user and check whether roles is present
 * @param {string} user - user stored in cookie
 * @returns {any | undefined} - any -> parsed user, undefined -> role is not present
 */
export const parseUserAndValidateRoles = (user: string): any | undefined => {
  const parsedUser: any = JSON.parse(user);
  return !isEmpty(parsedUser.roles) ? parsedUser : undefined;
};

/**
 * Get logged in user role
 * @param {string} userFromCookie - user string stored in cookie
 * @returns {string | undefined} - returns user role, if user is neither an Admin not a Viewer then returns undefined
 */
export const getUserRole = (userFromCookie: string): string | undefined => {
  const parsedUser: any = parseUserAndValidateRoles(userFromCookie);

  // if user roles exists
  // eg: user.roles = ['Admin', 'View']
  if (parsedUser) {
    // if user is an Admin, Admin takes highest precedence
    if (parsedUser.roles.includes(process.env.REACT_APP_AZURE_ADMIN_ROLE)) {
      return process.env.REACT_APP_AZURE_ADMIN_ROLE;
    }

    if (parsedUser.roles.includes(process.env.REACT_APP_AZURE_VIEWER_ROLE)) {
      // if user is a viewer
      return process.env.REACT_APP_AZURE_VIEWER_ROLE;
    }
  }
};

/**
 * Logout microsoft instance
 * @param {IPublicClientApplication} instance
 */
export const handleLogout = async (instance: IPublicClientApplication) => {
  deleteCookie();
  const homeId = instance.getActiveAccount()?.homeAccountId;
  if (homeId)
    instance
      .logoutRedirect({
        account: instance.getAccountByHomeId(homeId),
        postLogoutRedirectUri: process.env.REACT_APP_LOGOUT_RETURN_URL
      })
      .catch(e => {
        console.error(e);
      });
};
