import '../../utils/rank-object.utils';

import { Button, Form, Radio, Select, Tooltip, message } from 'antd';
import React, { useEffect, useState } from 'react';
import { isEmpty, isUndefined } from 'lodash';

import { CarrierPriorityService } from '../../service/carrier-priority.service';
import CarrierPriorityTable from './CarrierPriorityTable';
import { CsvDetail } from '../../types/data/csvDetail';
import { Helmet } from 'react-helmet';
import { InfoCircleOutlined } from '@ant-design/icons';
import { MedicareQuoteType } from '../../enums/medicare-quote-type.enum';
import { PageHeader } from '@ant-design/pro-layout';
import { SASortType } from '../../enums/sa-sort-type';
import SecureComponent from '../../components/authorization';
import { TooltipConstants } from '../../constants/tooltip.constants';

const CarrierPriority = () => {
  const [form] = Form.useForm();
  const tempQuoteType: string = Form.useWatch('type', form);
  const layout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 14 }
  };
  const [data, setData] = useState<CsvDetail[]>([]);
  const [options, setOptions] = useState();

  const [showCarrierPriorityTable, setShowCarrierPriorityTable] =
    useState<boolean>(false);
  const [tempSortTypeValue, setTempSortTypeValue] =
    React.useState<SASortType>();
  const [isSubmitButtonDisabled, setIsSubmitButtonDisabled] =
    useState<boolean>(false);
  const [quoteType, setQuoteType] = useState(tempQuoteType);
  const [sortTypeValue, setSortTypeValue] = useState(tempSortTypeValue);

  const resetForm = () => {
    setSortTypeValue(undefined);
    setShowCarrierPriorityTable(false);
    form.resetFields();
    setQuoteType('');
    setTempSortTypeValue(undefined);
    setOptions(undefined);
    setData([]);
  };

  useEffect(() => {
    if (quoteType !== tempQuoteType || sortTypeValue !== tempSortTypeValue)
      setIsSubmitButtonDisabled(false);
    else setIsSubmitButtonDisabled(true);
  }, [tempQuoteType, tempSortTypeValue]);

  const getMasterData = () => {
    CarrierPriorityService.getMasterData({
      'quote-type': tempQuoteType,
      'sort-type': tempSortTypeValue
    })
      .then(response => {
        setOptions(response);
      })
      .catch((err: any) => {
        message.error(`${err}`);
      });
  };
  const downloadRankObjectData = () => {
    CarrierPriorityService.downloadRankObjects({
      'quote-type': tempQuoteType,
      'sort-type': tempSortTypeValue
    })
      .then(response => {
        setData(response.csvDetail);
      })
      .catch(() => {
        setData([]);
        // message.error(`${err.response.data.message}`);
      });
  };

  const viewPriorities = async () => {
    if (isUndefined(tempQuoteType) || isUndefined(tempSortTypeValue))
      message.error('Please select Type and Sort Type');
    else {
      setQuoteType(tempQuoteType);
      setSortTypeValue(tempSortTypeValue);
      downloadRankObjectData();
      getMasterData();
    }
  };

  useEffect(() => {
    if (!isEmpty(options) && !isUndefined(data)) {
      setShowCarrierPriorityTable(true);
      setIsSubmitButtonDisabled(true);
    }
  }, [options, data]);

  return (
    <>
      <Helmet>
        <title>
          Carrier Priority | Quoting Tool SA Dashboard | Elite Insurance
          Partners
        </title>
        <meta
          name='description'
          content={`Elite Insurance Partners | Quoting Tool SA Dashboard - Carrier Priority`}
        />
      </Helmet>
      <Form style={{ height: '100%', overflowY: 'auto' }} form={form}>
        <PageHeader
          className='space-top-10'
          ghost={false}
          style={{ background: '#ffffff' }}
          title={'Setup Carrier Priority'}
          subTitle={
            <Tooltip
              title={TooltipConstants.GENERAL_DISCLAIMER}
              placement='right'
              overlayInnerStyle={{ width: '500px' }}>
              <InfoCircleOutlined style={{ alignSelf: 'center' }} />
            </Tooltip>
          }>
          <Form {...layout} form={form}>
            <Form.Item
              name='type'
              label='Type'
              tooltip={{
                title: `${TooltipConstants.QUOTE_TYPE}`,
                icon: <InfoCircleOutlined />
              }}
              rules={[
                {
                  required: true,
                  message: 'Please choose a type'
                }
              ]}>
              <Select>
                {Object.values(MedicareQuoteType).map((operator, index) => {
                  return (
                    <Select.Option key={index} value={operator}>
                      {Object.keys(MedicareQuoteType)[index]}
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
            <Form.Item
              name='sortType'
              label='Sort Type'
              tooltip={{
                title: `${TooltipConstants.SORT_TYPE}`,
                icon: <InfoCircleOutlined />
              }}
              rules={[
                {
                  required: true,
                  message: 'Please choose a field'
                }
              ]}>
              <Radio.Group
                onChange={e => {
                  setTempSortTypeValue(e.target.value);
                }}
                value={sortTypeValue}>
                {Object.keys(SASortType).map((sortType, index) => (
                  <Radio
                    value={SASortType[sortType as keyof typeof SASortType]}
                    key={index}>
                    {sortType}
                  </Radio>
                ))}
              </Radio.Group>
            </Form.Item>
            <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
              <Button
                style={{ marginRight: '8px' }}
                type='primary'
                disabled={
                  isEmpty(tempQuoteType) ||
                  isEmpty(tempSortTypeValue) ||
                  isSubmitButtonDisabled
                }
                onClick={viewPriorities}>
                View Priorities
              </Button>
              <Button htmlType='button' onClick={resetForm}>
                Reset
              </Button>
            </Form.Item>
          </Form>
        </PageHeader>
        {showCarrierPriorityTable && (
          <CarrierPriorityTable
            typeofQuote={quoteType}
            typeofSort={sortTypeValue}
            value={data}
            masterData={options}
          />
        )}
      </Form>
    </>
  );
};

export default SecureComponent(CarrierPriority);
