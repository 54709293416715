import {
  Col,
  Form,
  FormInstance,
  Input,
  InputNumber,
  Modal,
  Row,
  Select
} from 'antd';
import React, { useEffect, useState } from 'react';
import { capitalize, isEmpty, isUndefined, uniq } from 'lodash';

import { CsvDetail } from '../../types/data/csvDetail';
import ZipCodeService from '../../service/zipCodeService';

type CarrierPriorityModalProps = {
  loading?: boolean;
  visible: boolean;
  handleOk: (data: any) => void;
  handleCancel: (event: any) => void;
  data?: any;
  typeofQuote: string;
  typeofSort?: string;
  masterData: any;
  type: string;
  keys: string[];
};

const CarrierPriorityModal = (props: CarrierPriorityModalProps) => {
  const [value, setValue] = useState<string>('');
  const [attribute, setAttribute] = useState<string>('');
  const [selectedState, setSelectedState] = useState<string>('');
  const [selectedCounty, setSelectedCounty] = useState<string>();
  const [childAttribute, setChildAttribute] = useState<string>();
  const [childValue, setChildValue] = useState<string>();
  const [rank, setRank] = useState<number>(0);
  const [state, setState] = useState<string>('');
  const [county, setCounty] = useState<string[]>([]);
  const [isValidZip, setIsValidZip] = useState<boolean>(true);
  const [zip, setZip] = useState<string>('');
  const [form] = Form.useForm();

  const formModalRef: React.RefObject<FormInstance> =
    React.createRef<FormInstance>();

  const handleFormSubmit = () => {
    formModalRef.current
      ?.validateFields()
      .then(() => {
        const data_: CsvDetail = {
          zip: zip,
          rank: rank,
          stateCode: state,
          value: value,
          attribute: attribute
        };
        if (!isUndefined(selectedCounty)) {
          data_.county = selectedCounty;
        }
        if (!isUndefined(childAttribute)) {
          data_.childAttribute = childAttribute;
        }

        if (!isUndefined(childValue)) {
          data_.children = childValue;
        }
        props.handleOk(data_);
      })
      .catch(err => {
        console.log('Form has validation errors', err);
      });
  };

  const getZipDetails = async (zipCode: string) => {
    setIsValidZip(false);
    if (ZipCodeService.isValidZipCode(zipCode)) {
      try {
        setIsValidZip(true);
        const response: any = await ZipCodeService.getCountyForZip(zipCode);
        const stateAbbreviations: string =
          response.zipcodes[0].state_abbreviation;
        const counties: string[] = [];
        if (response.zipcodes && response.zipcodes.length > 0) {
          response.zipcodes.forEach((zipObject: any) => {
            if (!isEmpty(zipObject.county_name)) {
              counties.push(zipObject.county_name);
            }
            if (
              zipObject.alternate_counties &&
              zipObject.alternate_counties.length > 0
            ) {
              zipObject.alternate_counties.forEach((altCounties: any) => {
                if (!isEmpty(altCounties.county_name)) {
                  counties.push(altCounties.county_name);
                }
              });
            }
          });
        }
        setState(stateAbbreviations);
        setCounty(counties);
      } catch (error) {
        setIsValidZip(false);
      }
    }
  };

  useEffect(() => {
    setZip(zip);
    setIsValidZip(isValidZip);
    setCounty(county);
    setState(state);
  }, [zip]);

  const handleCountyChange = (value: string) => {
    if (value !== null) setSelectedCounty(value);
  };

  const handleRankChange = (value: number | null) => {
    if (value !== null) setRank(value);
  };
  const handleChildAttributeChange = (value: string) => {
    setChildAttribute(value);
    form.setFieldsValue({ ChildrenValue: undefined });
  };

  const handleAttributeChange = (value: string) => {
    setAttribute(value);
    form.setFieldsValue({ Value: undefined });
  };

  const handleValueChange = (value: string | null) => {
    if (value !== null) setValue(value);
  };

  const handleChildValueChange = (value: string[] | null | string) => {
    if (Array.isArray(value)) {
      const array = value.flatMap(item => {
        if (item.includes(';')) {
          return item.split(';');
        } else {
          return item;
        }
      });
      const selectedValues = uniq(array).join(';');
      setChildValue(selectedValues);
    } else if (typeof value === 'number') {
      setChildValue(value);
    } else if (typeof value === 'string') {
      setChildValue(value);
    }
  };

  useEffect(() => {
    if (state === 'MA') setSelectedState('MA');
    else if (state === 'MN') setSelectedState('MN');
    else if (state === 'WI') setSelectedState('WI');
    else setSelectedState('remainingStates');
  }, [state]);

  useEffect(() => {
    if (!isUndefined(props.data)) {
      setZip(props.data.zip);
      setState(props.data.stateCode);
      setSelectedCounty(props.data.county);
      setAttribute(props.data.attribute);
      setChildAttribute(props.data.childAttribute);
      setRank(props.data.rank);
      setValue(props.data.value);
      setChildValue(props.data.children);
      if (props.data.stateCode === 'MA') setSelectedState('MA');
      else if (props.data.stateCode === 'MN') setSelectedState('MN');
      else if (props.data.stateCode === 'WI') setSelectedState('WI');
      else setSelectedState('remainingStates');
    }
  }, [props.data]);

  const filterValueOptions = (attr?: string): string[] => {
    let filteredOptions: string[] = [];
    if (attr === 'carrier' || attribute === 'carrier') {
      filteredOptions = props.masterData['carrier'];
    } else if (
      (attr === 'planType' || attribute === 'planType') &&
      selectedState
    ) {
      filteredOptions = props.masterData['planType'][selectedState] || [];
    }
    const uniqueOptions = Array.from(new Set(filteredOptions));
    return uniqueOptions;
  };

  const filterValueOptionsChild = (): string[] => {
    let filteredOptions: string[] = [];
    if (childAttribute === 'carrier') {
      filteredOptions = props.masterData['carrier'];
    } else if (childAttribute === 'planType' && selectedState) {
      filteredOptions = props.masterData['planType'][selectedState] || [];
    }

    return filteredOptions;
  };

  useEffect(() => {
    if (!isValidZip) {
      form.validateFields(['Zip Code']);
    }
  }, [isValidZip, zip]);

  return (
    <div>
      <Modal
        className='editModal'
        title={
          props.type === 'add'
            ? 'Add New Row'
            : `Edit Config - ${props.data.stateCode}`
        }
        onOk={handleFormSubmit}
        onCancel={props.handleCancel}
        open={props.visible}
        okText={props.type === 'edit' ? 'Update Config' : 'Add'}
        cancelText={'Cancel'}
        destroyOnClose={true}>
        <Form
          ref={formModalRef}
          style={{
            paddingTop: '1rem'
          }}
          labelWrap
          initialValues={
            props.type === 'edit'
              ? {
                  'Zip Code': props.data.zip,
                  StateCode: props.data.stateCode,
                  County: props.data.county,
                  Attribute: props.data.attribute,
                  Value: props.data.value,
                  'Children Attribute': props.data.childAttribute,
                  ChildrenValue: props.data.children,
                  Rank: props.data.rank
                }
              : {}
          }
          layout='horizontal'
          labelAlign='right'
          form={form}>
          <Row>
            <Col
              span={9}
              style={{
                marginRight: '10px'
              }}>
              <Form.Item
                name='Zip Code'
                label='Zip Code'
                tooltip='Zip Code'
                labelCol={{ span: 8 }}
                rules={[
                  {
                    required: true,
                    validator: (_, value) => {
                      if (!value) {
                        return Promise.reject('Please enter a Zip Code');
                      }
                      if (!isValidZip) {
                        return Promise.reject('Enter valid Zip Code');
                      }
                      return Promise.resolve();
                    }
                  }
                ]}>
                <Input
                  onChange={e => {
                    setZip(e.target.value);
                    const isValid = ZipCodeService.isValidZipCode(
                      e.target.value
                    );
                    setIsValidZip(isValid);
                    isValid
                      ? (getZipDetails(e.target.value),
                        form.setFieldsValue({ County: undefined }),
                        form.setFieldsValue({ StateCode: undefined }))
                      : (form.setFieldsValue({ County: undefined }),
                        form.setFieldsValue({ StateCode: undefined }),
                        setState(''),
                        setCounty([]));
                  }}
                  placeholder='Zip Code'
                  status={isValidZip ? '' : 'error'}
                />
              </Form.Item>

              <Form.Item
                name='Attribute'
                label='Attribute'
                tooltip='Attribute'
                labelCol={{ span: 8 }}
                rules={[
                  { required: true, message: 'Please enter a valid Attribute' }
                ]}>
                <Select
                  allowClear
                  showSearch
                  placeholder='Attribute'
                  options={props.masterData.attributes
                    .filter((option: string) => option !== childAttribute)
                    .map((option: string) => ({
                      value: option,
                      label: option
                    }))}
                  onChange={handleAttributeChange}
                />
              </Form.Item>
              {props.masterData.childAttribute && (
                <Form.Item
                  name='Children Attribute'
                  label='Child Attribute'
                  tooltip='Child Attribute'
                  labelCol={{ span: 8 }}
                  //style={{ margin: '1rem' }}
                  rules={[
                    {
                      required: true,
                      message: 'Please enter a valid Children Attribute'
                    }
                  ]}>
                  <Select
                    allowClear
                    showSearch
                    placeholder='Children Attribute'
                    options={props.masterData.childAttribute
                      .filter((option: string) => option !== attribute)
                      .map((option: string) => ({
                        value: option,
                        label: option
                      }))}
                    onChange={handleChildAttributeChange}
                  />
                </Form.Item>
              )}
              <Form.Item
                name='Rank'
                label='Rank'
                tooltip='Rank'
                labelCol={{ span: 8 }}
                rules={[
                  { required: true, message: 'Please enter a valid Rank' }
                ]}>
                <InputNumber min={1} max={10} onChange={handleRankChange} />
              </Form.Item>
            </Col>
            <Col
              span={8}
              style={{
                marginRight: '10px'
              }}>
              <Form.Item
                name='StateCode'
                label='State Code'
                tooltip='State Code'
                labelCol={{ span: 8 }}
                //style={{ margin: '1rem' }}
                rules={[
                  {
                    required: false,
                    message: 'Please enter a valid State Code'
                  }
                ]}>
                {state === '' ? (
                  <Select placeholder='State Code' />
                ) : (
                  <Input defaultValue={state} readOnly />
                )}
              </Form.Item>
              <Form.Item
                name='Value'
                label='Value'
                tooltip='Value'
                labelCol={{ span: 8 }}
                rules={[
                  { required: true, message: 'Please enter a valid Value' }
                ]}>
                {attribute === 'monthlyPremium' ? (
                  <InputNumber
                    min={'0'}
                    max={'1000'}
                    onChange={handleValueChange}
                  />
                ) : (
                  <Select
                    showSearch
                    style={{
                      width:
                        attribute === 'carrier' &&
                        props.typeofQuote !== 'medicareSupplement'
                          ? '175%'
                          : '100%'
                    }}
                    placeholder='Value'
                    options={filterValueOptions().map((value: string) => ({
                      value,
                      label: capitalize(value)
                    }))}
                    onChange={handleValueChange}
                  />
                )}
              </Form.Item>
              {props.masterData.childAttribute && (
                <Form.Item
                  name='ChildrenValue'
                  label='Child Value'
                  tooltip='Child Value'
                  labelCol={{ span: 8 }}
                  // //style={{ margin: '1rem' }}
                  rules={[
                    {
                      required: true,
                      message: 'Please enter a valid Children Value'
                    }
                  ]}>
                  {childAttribute === 'monthlyPremium' ? (
                    <InputNumber
                      min={'0'}
                      max={'1000'}
                      onChange={handleChildValueChange}
                    />
                  ) : (
                    <Select
                      allowClear
                      showSearch
                      mode={
                        childAttribute === 'planType' ? 'multiple' : undefined
                      }
                      placeholder='Children Value'
                      options={filterValueOptionsChild().map(
                        (value: string) => ({
                          value,
                          label: value
                        })
                      )}
                      onChange={handleChildValueChange}
                    />
                  )}
                </Form.Item>
              )}
            </Col>
            <Col span={9}>
              {props.typeofQuote !== 'medicareSupplement' && (
                <Form.Item
                  name='County'
                  label='County'
                  tooltip='County'
                  rules={[
                    { required: true, message: 'Please enter a valid County' }
                  ]}>
                  <Select
                    showSearch
                    placeholder='County'
                    options={
                      county && county.length > 0
                        ? county.map(abbreviation => ({
                            value: abbreviation,
                            label: abbreviation
                          }))
                        : []
                    }
                    onChange={handleCountyChange}
                  />
                </Form.Item>
              )}
            </Col>
          </Row>
        </Form>
      </Modal>
    </div>
  );
};

export default CarrierPriorityModal;
