import configReducer from './reducers/config.reducer';
import { configureStore } from '@reduxjs/toolkit';

const rootStore = configureStore({
  reducer: {
    configStore: configReducer
  }
});

export default rootStore;

export type RootState = ReturnType<typeof rootStore.getState>;
