import { AuthenticationResult, SilentRequest } from '@azure/msal-browser';
import { Button, Result } from 'antd';
import { ReactElement, useEffect } from 'react';
import {
  getTokenForBrowser,
  getUserRole,
  handleLogout,
  saveCookie
} from '../../utils/auth.utils';

import LoadingComponent from '../../components/loading';
import { RouteConstants } from '../../constants/route.constants';
import { useMsal } from '@azure/msal-react';
import { useNavigate } from 'react-router';

const RedirectPage = () => {
  const { instance } = useMsal();
  const navigate = useNavigate();

  // get user token
  const getJWTToken = async (): Promise<void> => {
    const account = instance.getActiveAccount();

    await instance
      .acquireTokenSilent((account ? { account } : {}) as SilentRequest)
      .then((tokenResponse: AuthenticationResult) => {
        saveCookie(tokenResponse.idToken);
      })
      .catch(error => {
        console.log('error while fetching token redirect :: ', error);
        navigate(RouteConstants.LOGIN);
      });
  };

  // redirect user to appropriate page
  const redirectUser = async (): Promise<ReactElement | undefined> => {
    const userFromCookie: string | undefined | false =
      await getTokenForBrowser();
    // if user token exists
    if (userFromCookie) {
      const userRole: string | undefined = getUserRole(userFromCookie);

      // if user is neither an Admin nor a Viewer
      if (!userRole)
        return (
          <Result
            status={'warning'}
            title='Unauthorized access'
            extra={[
              <Button
                type='primary'
                key='logout'
                onClick={() => handleLogout(instance)}>
                Logout
              </Button>
            ]}
          />
        );
      else if (
        userRole === process.env.REACT_APP_AZURE_ADMIN_ROLE ||
        userRole === process.env.REACT_APP_AZURE_VIEWER_ROLE
      )
        navigate(RouteConstants.CONFIGURATIONS);
      else navigate(RouteConstants.LOGIN);
    } else navigate(RouteConstants.LOGIN);
  };

  useEffect(() => {
    getJWTToken().then(() => 
      redirectUser()
    );
  }, []);

  return <LoadingComponent tip='Loading your account' />;
};

export default RedirectPage;
