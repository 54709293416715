import { Form, FormInstance, Input, Modal } from 'antd';

import { EmailTemplate } from '../../../../types/data/emailTemplate.type';
import React from 'react';

type EditEmailTemplateModalProps = {
  loading: boolean;
  visible: boolean;
  handleOk: (data: any) => void;
  handleCancel: (event: any) => void;
  data: EmailTemplate;
};

const EditEmailTemplateModal = (props: EditEmailTemplateModalProps) => {
  const formModalRef: React.RefObject<FormInstance> =
    React.createRef<FormInstance>();

  const handleFormSubmit = () => {
    formModalRef.current
      ?.validateFields()
      .then(data => {
        const data_: EmailTemplate = {
          key: props.data.key,
          emailBody: data.emailBody,
          emailSubject: data.emailSubject
        };
        props.handleOk(data_);
      })
      .catch(err => {
        console.log('Form has validation errors', err);
      });
  };

  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 }
  };

  if (props.data)
    return (
      <div>
        <Modal
          title={`Edit Email template - ${props.data.key}`}
          visible={props.visible}
          onOk={handleFormSubmit}
          onCancel={props.handleCancel}
          okText='Update template'
          okButtonProps={{ loading: props.loading }}
          cancelText='Cancel'
          cancelButtonProps={{ disabled: props.loading }}
          destroyOnClose={true}>
          <Form
            {...layout}
            ref={formModalRef}
            initialValues={{ ...props.data }}
            style={{ height: '50vh', overflowY: 'auto' }}>
            <Form.Item name='key' label='Name'>
              <Input disabled />
            </Form.Item>
            <Form.Item
              name='emailSubject'
              label='Subject'
              rules={[
                {
                  required: true,
                  message: 'Please provide a valid value'
                }
              ]}>
              <Input.TextArea rows={2} showCount maxLength={255} />
            </Form.Item>
            <Form.Item
              name='emailBody'
              label='Email Body'
              rules={[
                {
                  required: true,
                  message: 'Please provide a valid value'
                }
              ]}>
              <Input.TextArea rows={10} showCount maxLength={2500} />
            </Form.Item>
          </Form>
        </Modal>
      </div>
    );
  else return null;
};

export default EditEmailTemplateModal;
