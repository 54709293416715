import { Button, Col, Row, Typography, message } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { Config } from '../../types/data/configuration.type';
import { ConfigurationService } from '../../service/configuration.service';
import { Helmet } from 'react-helmet';
import { IsAdminProp } from '../../types/props/isAdmin.props';
import { MSPlanCarrier } from '../../types/data/ms-plan-carrier.type';
import { MSPlanCarrierTableComponent } from '../../components/ms-plan-carriers';
import { RouteConstants } from '../../constants/route.constants';
import { SecureComponent } from '../../components';

const MSPlanCarrierPage: React.FC<IsAdminProp> = () => {
  const [loading, setLoading] = useState(false);
  const [msPlanCarriers, setMsPlanCarriers] = useState<MSPlanCarrier[]>([]);
  const [selectedCarriers, setSelectedCarriers] = useState<string[]>([]);
  const [naicIds, setNaicIds] = useState<string[]>([]);

  const navigate = useNavigate();

  const getMSPlanCarriers = () => {
    setLoading(true);
    ConfigurationService.getAllMSCarriers()
      .then((msCarriers: MSPlanCarrier[]) => {
        setMsPlanCarriers(msCarriers);
        const naicIds: string[] = msCarriers
          .filter((carrier: MSPlanCarrier) => {
            if (carrier.toQuery) return carrier;
          })
          .map(carrier => carrier.naic);
        setNaicIds(naicIds);
        setSelectedCarriers(naicIds);
      })
      .catch(err => {
        console.log('Error while fetching carriers :: ', err);
        message.error('Failed to fetch carriers, please try again later');
        navigate(RouteConstants.CONFIGURATIONS);
      })
      .finally(() => setLoading(false));
  };
  useEffect(() => {
    getMSPlanCarriers();
  }, []);

  const location = useLocation();
  const config = location.state as Config;
  const handleSelectCarriers = (data: any) => {
    setSelectedCarriers(data);
  };

  const handleSaveCarriers = () => {
    setLoading(true);
    const data: Config = {
      key: config.key,
      value: selectedCarriers.join() as string,
      description: config.description,
      type: config.type,
      id: config.id
    };

    ConfigurationService.updateMSCompanies(data)
      .then(() => {
        message.success('Configuration Updated Successfully');
      })
      .catch(err => {
        console.log('Error while updating MS Companies in DB :: ', err);
        message.error('Failed to update MS Companies, please try again later');
      })
      .finally(() => {
        setLoading(false);
        getMSPlanCarriers();
      });
  };
  return (
    <>
      <Helmet>
        <title>
          MS Select Carriers | Quoting Tool SA Dashboard | Elite Insurance
          Partners
        </title>
        <meta
          name='description'
          content={`Elite Insurance Partners | Quoting Tool SA Dashboard - Configurations`}
        />
      </Helmet>
      <Row>
        <Col xs={10}>
          <Typography.Title level={3}>
            Configurations - Medicare Supplement Plan Carriers
          </Typography.Title>
        </Col>
      </Row>
      <PageHeader
        className='space-top-10'
        ghost={false}
        onBack={() => navigate(RouteConstants.CONFIGURATIONS)}
        title={'Medicare Supplement Plan Carriers'}
        subTitle={`Selected ${selectedCarriers.length} items from ${msPlanCarriers.length}`}
        extra={[
          <Button
            key='1'
            type='primary'
            disabled={selectedCarriers.length <= 0}
            onClick={() => handleSaveCarriers()}>
            Save
          </Button>,
          <Button key='2' onClick={() => handleSelectCarriers(naicIds)}>
            Undo
          </Button>
        ]}>
        <Row gutter={16} className='space-top-10' justify='center'>
          <Col xs={16} className='space-top-10'>
            <MSPlanCarrierTableComponent
              loading={loading}
              msPLanCarriers={msPlanCarriers}
              handleSelect={handleSelectCarriers}
              selectedRows={selectedCarriers}
            />
          </Col>
        </Row>
      </PageHeader>
    </>
  );
};
export default SecureComponent(MSPlanCarrierPage, false);
