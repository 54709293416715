import { Button, Col, Row, Typography, message } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { Config } from '../../types/data/configuration.type';
import { ConfigurationService } from '../../service/configuration.service';
import { Helmet } from 'react-helmet';
import { IsAdminProp } from '../../types/props/isAdmin.props';
import { MNSPlanCarrier } from '../../types/data/mns-plan-carrier.type';
import { MNSPlanCarrierTableComponent } from '../../components/mns-plan-carriers';
import { MedicareQuoteType } from '../../enums/medicare-quote-type.enum';
import { RouteConstants } from '../../constants/route.constants';
import { SecureComponent } from '../../components';

const MPartDPlanCarrierPage: React.FC<IsAdminProp> = () => {
  const [loading, setLoading] = useState(false);
  const [mnsPlanCarriers, setMnsPlanCarriers] = useState<MNSPlanCarrier[]>([]);
  const [preSelectedCarriers, setPreSelectedCarriers] = useState<string[]>([]);
  const [selectedCarriers, setSelectedCarriers] = useState<string[]>([]);
  const navigate = useNavigate();
  const getMNSPlanCarriers = () => {
    setLoading(true);
    ConfigurationService.getAllMNSCarriers({
      'quote-type': MedicareQuoteType['Medicare Prescription']
    })
      .then((mnsCarriers: MNSPlanCarrier[]) => {
        setMnsPlanCarriers(mnsCarriers);
        const preSelectedCarriers: string[] = mnsCarriers
          .filter((carrier: MNSPlanCarrier) => {
            if (carrier.toQueryMPartD) {
              return carrier;
            }
          })
          .map(carrier => carrier.id);
        setPreSelectedCarriers(preSelectedCarriers);
        setSelectedCarriers(preSelectedCarriers);
      })
      .catch(err => {
        console.log('Error while fetching carriers :: ', err);
        message.error('Failed to fetch carriers, please try again later');
        navigate(RouteConstants.CONFIGURATIONS);
      })
      .finally(() => setLoading(false));
  };
  useEffect(() => {
    getMNSPlanCarriers();
  }, []);

  const location = useLocation();
  const config = location.state as Config;
  const handleSelectCarriers = (data: any) => {
    setSelectedCarriers(data);
  };

  const handleSaveCarriers = () => {
    setLoading(true);
    ConfigurationService.updateMNSCarrierConfigs(
      selectedCarriers,
      MedicareQuoteType['Medicare Prescription'],
      config.id
    )
      .then(() => {
        message.success('Configuration Updated Successfully');
      })
      .catch(err => {
        console.log(
          'Error while updating Medicare Part D Carrier Configuration :: ',
          err
        );
        message.error(
          'Failed to update Medicare Part D Carrier Configuration, please try again later'
        );
      })
      .finally(() => {
        setLoading(false);
        getMNSPlanCarriers();
      });
  };

  return (
    <>
      <Helmet>
        <title>
          M-PART-D Select Carriers | Quoting Tool SA Dashboard | Elite Insurance
          Partners
        </title>
        <meta
          name='description'
          content={`Elite Insurance Partners | Quoting Tool SA Dashboard - Configurations`}
        />
      </Helmet>
      <Row>
        <Col xs={10}>
          <Typography.Title level={3}>
            Configurations - Medicare Part D Plan Carriers
          </Typography.Title>
        </Col>
      </Row>
      <PageHeader
        className='space-top-10'
        ghost={false}
        onBack={() => navigate(RouteConstants.CONFIGURATIONS)}
        title={'Medicare Part D Plan Carriers'}
        subTitle={`Selected ${selectedCarriers.length} items from ${mnsPlanCarriers.length}`}
        extra={[
          <Button
            key='1'
            type='primary'
            disabled={selectedCarriers.length <= 0}
            onClick={() => handleSaveCarriers()}>
            Save
          </Button>,
          <Button
            key='2'
            onClick={() => handleSelectCarriers(preSelectedCarriers)}>
            Undo
          </Button>
        ]}>
        <Row gutter={16} className='space-top-10' justify='center'>
          <Col xs={16} className='space-top-10'>
            <MNSPlanCarrierTableComponent
              loading={loading}
              mnsPLanCarriers={mnsPlanCarriers}
              handleSelect={handleSelectCarriers}
              selectedRows={selectedCarriers}
            />
          </Col>
        </Row>
      </PageHeader>
    </>
  );
};
export default SecureComponent(MPartDPlanCarrierPage, false);
