import { Button, Col, Row, Typography, message } from 'antd';
import React, { useEffect, useState } from 'react';

import EditLeadManagementModal from '../../modals/lead-management/update';
import { Helmet } from 'react-helmet';
import { IsAdminProp } from '../../types/props/isAdmin.props';
import LeadManagementComponent from '../../components/lead-management';
import { LeadManagementService } from '../../service/lead-management.service';
import { LeadManagementType } from '../../types/data/lead-management.type';
import PriorityConfirmationModal from '../../modals/lead-management/priorityConfirmation';
import RemoveConfirmationModal from '../../modals/lead-management/remove';
import SecureComponent from '../../components/authorization';
import orderBy from 'lodash.orderby';

const LeadManagement: React.FC<IsAdminProp> = () => {
  const [addRuleModalVisible, setaddRuleModalVisible] = useState(false);
  const [
    deleteRuleConfirmationModalVisible,
    setdeleteRuleConfirmationModalVisible
  ] = useState(false);
  const [rulesData, setRulesData] = useState<LeadManagementType[]>([]);
  const [ruleInFocus, setRuleInFocus] = useState<LeadManagementType | null>(
    null
  );
  const [salesforceFieldsList, setSalesforceFieldsList] = useState([]);
  const [rowDragged, setRowDragged] = useState(false);
  const [isPrioritySaved, setIsPrioritySaved] = useState(true);
  const [isDataFetching, setIsDataFetching] = useState(true);
  const [showPriorityConfirmationModal, setShowPriorityConfirmationModal] =
    useState(false);
  const [priorityChangeConfirmation, setPriorityChangeConfirmation] =
    useState(false);
  const [currentEditData, setCurrentEditData] = useState<LeadManagementType>();

  const handleRuleEdit = (e: any) => {
    setRuleInFocus(e);
    setaddRuleModalVisible(true);
  };

  const handleRuleDelete = (e: any) => {
    setRuleInFocus(e);
    setdeleteRuleConfirmationModalVisible(true);
  };

  const getLeadJourney = (response: LeadManagementType) => {
    const updatedLeadJourney = [];
    if (response.addressPage) updatedLeadJourney.push('Address Page');
    if (response.medicare101Page) updatedLeadJourney.push('Medicare 101');
    if (response.quotingToolPage) updatedLeadJourney.push('Quoting Tool');
    if (
      !response.addressPage &&
      !response.medicare101Page &&
      !response.quotingToolPage
    )
      updatedLeadJourney.push('Thanks Page');

    return updatedLeadJourney;
  };

  const getRulesData = () => {
    LeadManagementService.getRules()
      .then(res => {
        const rulesData = res.map((rule: LeadManagementType) => {
          return {
            ...rule,
            leadJourney: [
              rule.addressPage && 'Address Page',
              rule.medicare101Page && 'Medicare 101',
              rule.quotingToolPage && 'Quoting Tool',
              !rule.addressPage &&
                !rule.medicare101Page &&
                !rule.quotingToolPage &&
                'Thanks Page'
            ],
            statusActive: rule.status === 'active'
          };
        });
        setRulesData(rulesData);

        LeadManagementService.getSalesforceFields()
          .then(res => {
            const salesforceFieldsList = res.map((field: string) => {
              return { value: field, label: field };
            });
            setSalesforceFieldsList(salesforceFieldsList);
          })
          .catch(() => {
            message.error('Failed to fetch Salesforce Fields');
          });
      })
      .catch(() => {
        message.error(
          'Failed to fetch lead management rules, please try again later'
        );
      })
      .finally(() => {
        setIsDataFetching(false);
      });
  };

  const editRule = (cleanedData: any) => {
    setIsDataFetching(true);
    setaddRuleModalVisible(false);
    LeadManagementService.updateRule(cleanedData)
      .then(res => {
        const updatedLeadJourney = getLeadJourney(res);
        const tempRulesData = rulesData.map(ruleData => {
          if (ruleData.id === cleanedData.id) {
            cleanedData.ruleCondition = res.ruleCondition;
            cleanedData.leadJourney = updatedLeadJourney;
            return cleanedData;
          }
          return ruleData;
        });
        setRulesData(() => tempRulesData);
        message.success('Rule successfully updated');
      })
      .catch(() => {
        message.error(`Failed to update the rule, please try again later`);
      })
      .finally(() => {
        getRulesData();
        setPriorityChangeConfirmation(false);
      });
  };

  const addUpdateRule = async (data: any) => {
    const cleanedData = LeadManagementService.cleanRuleData(
      data,
      rulesData.length + 1
    );
    if (cleanedData.id) {
      setCurrentEditData(cleanedData);
      let priorityChanged = false;
      if (!priorityChangeConfirmation)
        rulesData.map(rule => {
          if (
            rule.id === cleanedData.id &&
            rule.priority !== cleanedData.priority
          )
            priorityChanged = true;
        });
      if (priorityChanged) setShowPriorityConfirmationModal(true);
      else editRule(cleanedData);
    } else {
      setIsDataFetching(true);
      setaddRuleModalVisible(false);
      LeadManagementService.addRule(cleanedData)
        .then(res => {
          const updatedLeadJourney = getLeadJourney(res);
          const updatedRulesData: LeadManagementType[] = rulesData.concat({
            ...res,
            leadJourney: updatedLeadJourney
          });
          setRulesData(updatedRulesData);
          message.success('Rule successfully added');
        })
        .catch(() => {
          message.error(`Failed to add rule, please try again later`);
        })
        .finally(() => {
          setIsDataFetching(false);
        });
    }
  };

  const deleteRule = () => {
    setIsDataFetching(true);
    setdeleteRuleConfirmationModalVisible(false);
    if (ruleInFocus && ruleInFocus.id) {
      LeadManagementService.deleteRule(ruleInFocus.id)
        .then(res => {
          console.log(res);
          const tempRulesData = rulesData.filter(
            rule => rule.id !== ruleInFocus.id
          );
          setRulesData(tempRulesData);
          message.success(`Rule: ${ruleInFocus.name} deleted successfully`);
        })
        .catch(() => {
          message.error(
            `Failed to delete rule: ${ruleInFocus.name}, please try again later`
          );
        })
        .finally(() => {
          setIsDataFetching(false);
        });
    }
  };

  const handlePriorityConfirmation = () => {
    setPriorityChangeConfirmation(true);
    setShowPriorityConfirmationModal(false);
    editRule(currentEditData);
  };

  const handleDragAndDrop = (fromIndex: any, toIndex: any) => {
    let data = rulesData;

    const from = fromIndex - 1;
    const to = toIndex - 1;

    const elm = data.splice(from, 1)[0];
    data.splice(to, 0, elm);

    data = data.map((rule, index) => {
      return {
        ...rule,
        priority: index + 1
      };
    });
    setRulesData(() => [...data]);
    setIsPrioritySaved(false);
    LeadManagementService.updatePriorities({
      id: elm.id,
      priority: toIndex,
      existingPriority: fromIndex
    })
      .then(() => {
        setIsPrioritySaved(true);
      })
      .catch(() => {
        message.error('Failed to update priority, please try again later');
        data = orderBy(data, 'priority', 'asc');
        setIsPrioritySaved(true);
      });
  };

  useEffect(() => {
    getRulesData();
  }, []);

  return (
    <>
      <Helmet>
        <title>
          LQ Lead Management | Quoting Tool SA Dashboard | Elite Insurance
          Partners
        </title>
        <meta
          name='description'
          content={`Elite Insurance Partners | Quoting Tool SA Dashboard - Lead Management`}
        />
      </Helmet>

      {addRuleModalVisible ? (
        <EditLeadManagementModal
          visible={addRuleModalVisible}
          loading={false}
          salesforceFields={salesforceFieldsList}
          data={ruleInFocus as LeadManagementType}
          rulesData={rulesData}
          handleOk={addUpdateRule}
          handleCancel={() => {
            setaddRuleModalVisible(false);
          }}
        />
      ) : (
        <>
          <Row>
            <Col xs={10} className='space-top-10'>
              <Typography.Title level={3}>
                Low Quality Lead Management
              </Typography.Title>
            </Col>
          </Row>
          <Row gutter={16} className='space-top-10'>
            <Col xs={24} style={{ textAlign: 'right', alignContent: 'center' }}>
              {rowDragged ? (
                <>
                  <Button type='primary' style={{ marginRight: '5px' }}>
                    Save Priority
                  </Button>
                  <Button
                    type='text'
                    style={{ margin: '0px 10px 0px 5px' }}
                    onClick={() => {
                      getRulesData();
                      setRowDragged(() => false);
                    }}>
                    Cancel
                  </Button>
                </>
              ) : null}
              <Button
                type='primary'
                onClick={handleRuleEdit}
                disabled={rowDragged}>
                + Add New Rule
              </Button>
            </Col>
          </Row>
          <Row gutter={16} className='space-top-10'>
            <Col xs={24} className='space-top-10'>
              <LeadManagementComponent
                data={rulesData}
                handleEdit={handleRuleEdit}
                handleRemove={handleRuleDelete}
                handleDragAndDrop={handleDragAndDrop}
                disabled={rowDragged}
                loading={!isPrioritySaved}
                isDataFetching={isDataFetching}
              />
            </Col>
          </Row>
        </>
      )}

      <RemoveConfirmationModal
        visible={deleteRuleConfirmationModalVisible}
        title={'Want to delete the Row?'}
        handleOk={deleteRule}
        handleCancel={() => {
          setdeleteRuleConfirmationModalVisible(false);
        }}
      />

      <PriorityConfirmationModal
        visible={showPriorityConfirmationModal}
        handleOk={() => {
          handlePriorityConfirmation();
        }}
        handleCancel={() => {
          setShowPriorityConfirmationModal(false);
        }}
      />
    </>
  );
};

export default SecureComponent(LeadManagement, false);
