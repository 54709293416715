import { AxiosRequestConfig } from 'axios';
import Cookie from 'js-cookie';
import { StringConstants } from '../constants/string.constants';
const getHeaders = (): AxiosRequestConfig => {
  return {
    headers: {
      Authorization: 'Bearer ' + Cookie.get(StringConstants.COOKIE.JWT)
    }
  };
};

/**
 * Util function to replace empty strings with null
 * @param data
 */
const cleanEmptyString = (data: any) => {
  Object.keys(data).forEach(key => {
    if (data[key] === '') data[key] = null;
  });

  return data;
};

export const ApiUtils = {
  getHeaders,
  cleanEmptyString
};
