import { Button, Form, Modal, Table, Tooltip, Typography, message } from 'antd';
import {
  DeleteOutlined,
  EditOutlined,
  InfoCircleOutlined,
  PlusOutlined
} from '@ant-design/icons';
import { camelCase, isUndefined, startCase, transform } from 'lodash';
import { useEffect, useState } from 'react';

import CarrierPriorityModal from '../../modals/carrierPriority';
import { CarrierPriorityService } from '../../service/carrier-priority.service';
import { ColumnsType } from 'antd/lib/table';
import { CsvDetail } from '../../types/data/csvDetail';
import { PriorityTableProps } from '../../types/props/priorityTable.props';

const CarrierPriorityTable = (props: PriorityTableProps) => {
  const [editModalvisibility, setEditModalVisibility] =
    useState<boolean>(false);
  const [selectedConfig, setSelectedConfig] = useState<CsvDetail>();
  const [tableData, setTableData] = useState<any[]>([]);
  const [index, setIndex] = useState<number>();
  const [form] = Form.useForm();
  const [addModalVisibility, setAddModalVisibility] = useState<boolean>(false);
  const [keysEdit, setKeysEdit] = useState<string[]>([]);
  let keys: string[] = [];
  const convertKeysToLowerCase = (arr: any) => {
    return arr.map((obj: any) =>
      Object.keys(obj).reduce((acc: any, key) => {
        acc[camelCase(key)] = obj[key];
        return acc;
      }, {})
    );
  };

  const convertKeysToUpperCase = (arr: any) => {
    return arr.map((obj: any) =>
      Object.keys(obj).reduce((acc: any, key) => {
        acc[startCase(key)] = obj[key];
        return acc;
      }, {})
    );
  };

  const getIndex = (array: CsvDetail[], data: CsvDetail) => {
    const index = array.findIndex(
      obj =>
        obj.zip === data.zip &&
        obj.stateCode === data.stateCode &&
        obj.county === data.county &&
        obj.attribute === data.attribute &&
        obj.value === data.value &&
        obj.childAttribute === data.childAttribute &&
        obj.children === data.children &&
        obj.rank === data.rank
    );
    return index;
  };

  const updateObjectByIndex = (array: any[], updatedObject: any) => {
    if (index !== -1 && !isUndefined(index)) {
      const updatedArray = [...array];
      updatedArray[index] = {
        ...updatedObject
      };
      return updatedArray;
    }
    return array;
  };

  const uploadRankObjects = (data: CsvDetail[], type: string) => {
    CarrierPriorityService.uploadRankObjects(
      convertKeysToLowerCase(data),
      props.typeofQuote,
      props.typeofQuote,
      props.typeofSort
    )
      .then(() => {
        setTableData(convertKeysToUpperCase(data));
        if (type === 'update') {
          message.success('Updated Successfully');
          setSelectedConfig(undefined);
        } else if (type === 'delete')
          message.success('Successfully deleted the row');
        else if (type === 'add') message.success('Added new row successfully');
      })
      .catch(error => {
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          message.error('Error: ' + error.response.data.message);
        } else {
          message.error('Something went wrong');
        }
      });
  };

  const handleConfigEditClick = (config: CsvDetail) => {
    const editRow = transform(
      config,
      function (result: any, val: any, key: any) {
        result[camelCase(key)] = val;
      }
    );
    let keys: string[] = [];
    if (editRow) {
      keys = Object.keys(editRow);
    }
    setKeysEdit(keys);
    const index = getIndex(convertKeysToLowerCase(tableData), editRow);
    setIndex(index);
    setSelectedConfig(editRow);
    setEditModalVisibility(true);
  };

  const handleUpdateRowClick = (data: any) => {
    const updatedTableData = updateObjectByIndex(tableData, data);
    const updatedData = updatedTableData.map(item => {
      const { ...newItem } = item;
      return newItem;
    });
    uploadRankObjects(updatedData, 'update');
    setEditModalVisibility(false);
  };

  const handleDeleteClick = (data: CsvDetail) => {
    Modal.confirm({
      title: 'Confirmation',
      content: 'Are you sure you want to delete this row?',
      okText: 'Yes',
      cancelText: 'No',
      cancelButtonProps: {
        style: {
          backgroundColor: 'red',
          borderColor: 'red',
          color: 'white'
        }
      },
      onOk: () => {
        const updatedArray = tableData.filter(item => item !== data);
        uploadRankObjects(updatedArray, 'delete');
      }
    });
  };

  const handleAddClick = () => {
    setAddModalVisibility(true);
  };

  if (Array.isArray(props.value) && props.value.length > 0) {
    keys = Array.from(new Set(props.value.flatMap(obj => Object.keys(obj))));
  } else {
    if (tableData.length > 0) {
      keys = Array.from(new Set(tableData.flatMap(obj => Object.keys(obj))));
    }
  }

  const columns: ColumnsType<CsvDetail> = [
    ...keys.map(key => ({
      title: startCase(key),
      dataIndex: startCase(key),
      key: startCase(key)
    })),
    {
      title: '',
      key: 'id',
      render: data => (
        <div>
          <Button type='primary' onClick={() => handleConfigEditClick(data)}>
            <EditOutlined /> Edit
          </Button>
          <span style={{ marginLeft: '8px' }}></span>
          <Button type='primary' danger onClick={() => handleDeleteClick(data)}>
            <DeleteOutlined /> Remove
          </Button>
        </div>
      ),
      align: 'center'
    }
  ];

  const dataSource: any[] = props.value.map((item: CsvDetail) => {
    const tableRow: any = {}; // Create a new row object with a unique key
    Object.entries(item).forEach(([key, value]) => {
      const capitalizedKey = startCase(key); // Capitalize the key
      tableRow[capitalizedKey] = value; // Set the value using the capitalized key
    });
    return tableRow;
  });

  useEffect(() => {
    setTableData(dataSource);
  }, [props.value]);

  const handleOk = (data: any) => {
    const updatedRow = {} as any;
    for (const [key, value] of Object.entries(data)) {
      updatedRow[startCase(key)] = value;
    }

    const updatedData = tableData.map(item => {
      const { ...newItem } = item;
      return newItem;
    });
    const updatedTableData = [...updatedData, updatedRow];
    uploadRankObjects(updatedTableData, 'add');

    setAddModalVisibility(false);
    setEditModalVisibility(false);
    form.resetFields();
  };
  const handleCancel = () => {
    setEditModalVisibility(false);
    setAddModalVisibility(false);
    form.resetFields();
    setSelectedConfig(undefined);
  };
  return (
    <div
      style={{
        paddingTop: '50PX',
        position: 'relative',
        paddingBottom: '50px'
      }}>
      <div style={{ height: '100%', overflowY: 'auto' }}>
        <Typography
          style={{
            display: 'flex',
            flexDirection: 'column',
            marginBottom: '15px'
          }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              marginBottom: '10px'
            }}>
            <Typography.Text
              style={{
                font: 'Roboto',
                fontSize: '20px',
                fontWeight: '500',
                lineHeight: '23px',
                height: '23px',
                display: 'inline-block'
              }}>
              {startCase(props.typeofQuote)} Plan {startCase(props.typeofSort)}
            </Typography.Text>
            <Tooltip
              title={
                startCase(props.typeofQuote) +
                ' Plan ' +
                startCase(props.typeofSort)
              }>
              <InfoCircleOutlined
                style={{
                  alignSelf: 'center',
                  color: '#00000073',
                  marginLeft: '5px',
                  marginBottom: '5px'
                }}
              />
            </Tooltip>
            <div style={{ flex: '1' }}></div>
            <Button
              style={{
                marginLeft: '10px',
                marginRight: '10px'
              }}
              type='primary'
              icon={<PlusOutlined />}
              onClick={handleAddClick}>
              Add new row
            </Button>
          </div>
          <Table
            columns={columns}
            dataSource={tableData}
            bordered
            pagination={false}
          />
        </Typography>
      </div>
      {editModalvisibility && (
        <CarrierPriorityModal
          visible={editModalvisibility}
          handleCancel={handleCancel}
          handleOk={handleUpdateRowClick}
          data={selectedConfig as CsvDetail}
          typeofQuote={props.typeofQuote}
          typeofSort={props.typeofSort}
          masterData={props.masterData}
          type='edit'
          keys={keysEdit}></CarrierPriorityModal>
      )}
      {addModalVisibility && (
        <CarrierPriorityModal
          visible={addModalVisibility}
          handleCancel={handleCancel}
          handleOk={handleOk}
          typeofQuote={props.typeofQuote}
          typeofSort={props.typeofSort}
          masterData={props.masterData}
          type='add'
          keys={keys}></CarrierPriorityModal>
      )}
    </div>
  );
};

export default CarrierPriorityTable;
