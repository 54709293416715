import 'antd/dist/reset.css';
import 'dragula/dist/dragula.css';

import { Checkbox, Table } from 'antd';
import React, { useEffect, useState } from 'react';
import { sortBy, uniq } from 'lodash';

import { ColumnsType } from 'antd/es/table';
import { EditConfigValues } from '../../types/data/edit-config-value';
import { MSPlanTypes } from '../../enums/MedicarePlanTypesEnum';
import dragula from 'dragula';

type draggableTableProps = {
  data: any;
  onChange: (value: any[]) => void;
  boostedCarriers: any;
  naicIds: string[];
};

// It is used to determine the starting and ending index of the dragged element
const getIndexInParent = (el: HTMLElement) =>
  el.parentNode && Array.from(el.parentNode.children).indexOf(el);

export default function DraggableTable(props: draggableTableProps) {
  const getInitialData = () => {
    const data = (() => {
      switch (props.data[0].key) {
        case 'MA_CONFIGURED_CARRIERS':
        case 'M_PART_D_CONFIGURED_CARRIERS':
        case 'SUNFIRE_MA_CONFIGURED_CARRIERS':
        case 'SUNFIRE_M_PART_D_CONFIGURED_CARRIERS':
          return uniq(props.data[0].value.split(':,')).map((values, index) => ({
            key: index,
            rank: 0,
            value: `${values}`
          }));
        case 'MS_PLAN_BOOSTED_CARRIERS':
          return Object.values(props.naicIds).map((values, index) => ({
            key: index,
            rank: 0,
            value: `${values}`
          }));
        case 'MS_PLAN_DEFAULT_PLAN_TYPES':
        case 'MS_PLAN_TYPES_FILTER_ORDER':
          return Object.values(MSPlanTypes).map((values, index) => ({
            key: index,
            rank: 0,
            value: `${values}`
          }));
      }
    })();

    let initialData: { rank: any; key: number; value: string }[] = [];
    if (data) {
      const initialselectedRowKeys = props.boostedCarriers.split(',');
      initialData = data.map(item => ({
        ...item,
        rank: initialselectedRowKeys.includes(item.value)
          ? initialselectedRowKeys.indexOf(item.value) + 1
          : 0
      }));
    }
    return initialData;
  };
  const [data, setData] = React.useState(getInitialData());
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>(
    data.filter(item => item.rank !== 0).map(item => item.key)
  );

  const handleCheckboxToggle = (record: any) => {
    const updatedSelectedRowKeys = [...selectedRowKeys];
    if (updatedSelectedRowKeys.includes(record.key)) {
      updatedSelectedRowKeys.splice(
        updatedSelectedRowKeys.indexOf(record.key),
        1
      );
    } else {
      updatedSelectedRowKeys.push(record.key);
    }

    setSelectedRowKeys(updatedSelectedRowKeys);

    const updatedDataSource = data.map(item => {
      if (updatedSelectedRowKeys.includes(item.key)) {
        item.rank = updatedSelectedRowKeys.indexOf(item.key) + 1;
      } else {
        item.rank = 0;
      }
      return item;
    });
    setData(updatedDataSource);
    props.onChange(data);
  };

  useEffect(() => {
    const sortedData = sortBy(data, item => {
      if (item.rank === 0) {
        return Infinity;
      }
      return item.rank;
    });
    setData(sortedData);
  }, [selectedRowKeys]);

  const columns: ColumnsType<EditConfigValues> = [
    {
      title: 'rank',
      dataIndex: 'rank',
      key: 'rank',
      align: 'center',
      width: '120px',
      render: (_, record, index) => {
        return (
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <Checkbox
              checked={selectedRowKeys.includes(record.key)}
              onChange={() => handleCheckboxToggle(record)}></Checkbox>
            <div style={{ paddingLeft: '60px' }}>
              {selectedRowKeys.includes(record.key) ? index + 1 : '-'}
            </div>
          </div>
        );
      },
      sorter: {
        compare: (a, b) => {
          if (a.rank === 0) {
            return 1;
          }
          if (b.rank === 0) return -1;
          return a.rank - b.rank;
        },
        multiple: 1
      },
      defaultSortOrder: 'ascend',
      sortOrder: 'ascend'
    },
    {
      title: 'value',
      dataIndex: 'value',
      align: 'center',
      width: '200px',
      sorter: {
        compare: (a, b) => a.value.localeCompare(b.value),
        multiple: 2
      }
    }
  ];

  const handleReorder = (dragIndex: number, draggedIndex: number) => {
    if (!selectedRowKeys.includes(data[dragIndex - 1].key)) {
      return;
    }
    setData(oldState => {
      const newState = [...oldState];
      const item = newState.splice(dragIndex - 1, 1)[0];
      newState.splice(draggedIndex - 1, 0, item);
      newState.map((obj, index) => {
        if (obj.rank != 0) obj.rank = index + 1;
      });
      props.onChange(newState);
      return newState;
    });
  };

  React.useEffect(() => {
    let start: number;
    let end: number;
    const container = document.querySelector(
      '#drag.ant-table-tbody'
    ) as HTMLElement;
    const drake = dragula([container], {
      // It is an instance of dragula that handles the drag-and-drop events and updates the state of the DraggableTable component.
      moves: (el: any) => {
        start = getIndexInParent(el) ?? 0;
        const record = data[start - 1];
        return (
          selectedRowKeys.includes(record.key) && // Only allow dragging of selected rows
          record.rank !== 0 // Only allow dragging if the row has a rank
        );
      }
    });

    drake.on('drop', (el: any) => {
      end = getIndexInParent(el) ?? 0;
      handleReorder(start, end);
    });
  }, [data, selectedRowKeys]);

  return (
    <Table
      className='draggable'
      columns={columns}
      pagination={false}
      dataSource={data}
      bordered={true}
      components={{
        body: {
          wrapper: (props: any) => <tbody {...props} id={'drag'} />
        }
      }}
      scroll={{ y: 160 }}
      sortDirections={['ascend']}
    />
  );
}
