import { ColumnsType } from 'antd/lib/table';
import { MSPlanCarrier } from '../../types/data/ms-plan-carrier.type';
import { columnSearchFilter } from '../table-utils/columnSearchFilter';

export const getMSPlanCarrierColumns = (): ColumnsType<MSPlanCarrier> => [
  {
    title: 'Name of the Carrier',
    dataIndex: 'name',
    key: 'name',
    align: 'center',
    width: '80%',
    ...columnSearchFilter<MSPlanCarrier>('name'),
    sorter: (a, b) => a.name.localeCompare(b.name)
  },
  {
    title: 'NAIC ID',
    dataIndex: 'naic',
    key: 'naic',
    align: 'center',
    width: '20%',
    ...columnSearchFilter<MSPlanCarrier>('naic'),
    sorter: (a, b) => a.naic.localeCompare(b.naic)
  }
];
