import { Col, Row, Typography, message } from 'antd';
import React, { useEffect, useState } from 'react';

import EditEmailTemplateModal from '../../../modals/templates/email/update';
import { EmailTemplate } from '../../../types/data/emailTemplate.type';
import { Helmet } from 'react-helmet';
import { IsAdminProp } from '../../../types/props/isAdmin.props';
import SecureComponent from '../../../components/authorization';
import { TableType } from '../../../enums/table-type.enum';
import { TemplateService } from '../../../service/template.service';
import TemplatesComponent from '../../../components/templates/all-templates';

const EmailTemplatesPage: React.FC<IsAdminProp> = () => {
  const [emailTemplates, setEmailTemplates] = useState([]);
  const [loading, setLoading] = useState(false);
  const [editEmailTemplateVisible, setEditEmailTemplateVisible] =
    useState(false);
  const [selectedEmailTemplate, setSelectedEmailTemplate] =
    useState<EmailTemplate>();

  const getAllEmailTemplates = () => {
    setLoading(true);
    TemplateService.getAllEmailTemplates()
      .then(emailTemplates => {
        setEmailTemplates(emailTemplates);
      })
      .catch(err => {
        console.log('Error while fetching email templates :: ', err);
        message.error(
          'Failed to fetch email templates, please try again later'
        );
      })
      .finally(() => setLoading(false));
  };

  const updateEmailTemplate = (template: EmailTemplate) => {
    setLoading(true);
    TemplateService.updateEmailTemplate(template)
      .then(() => {
        message.success('Email template updated successfully');
      })
      .catch(err => {
        console.log('Error while updating email template :: ', err);
        message.error(
          'Failed to updating email template, please try again later'
        );
      })
      .finally(() => {
        setLoading(false);
        setEditEmailTemplateVisible(false);
        setSelectedEmailTemplate(undefined);
        getAllEmailTemplates();
      });
  };

  useEffect(() => {
    getAllEmailTemplates();
  }, []);

  const handleTemplatesEditClick = (template: EmailTemplate) => {
    setEditEmailTemplateVisible(true);
    setSelectedEmailTemplate(template);
  };

  const handleEditTemplateCancel = () => {
    setEditEmailTemplateVisible(false);
    setSelectedEmailTemplate(undefined);
  };

  return (
    <>
      <Helmet>
        <title>
          Email Templates | Quoting Tool SA Dashboard | Elite Insurance Partners
        </title>
        <meta
          name='description'
          content={`Elite Insurance Partners | Quoting Tool SA Dashboard - Email Templates`}
        />
      </Helmet>
      <Row>
        <Col xs={24} className='space-top-10'>
          <Typography.Title level={3}>Email Templates</Typography.Title>
        </Col>
        <Col xs={24} className='space-top-10'>
          <TemplatesComponent
            loading={loading}
            templates={emailTemplates}
            type={TableType.EMAIL_TEMPLATES}
            handleEdit={handleTemplatesEditClick}
          />
        </Col>

        <EditEmailTemplateModal
          visible={editEmailTemplateVisible}
          loading={loading}
          data={selectedEmailTemplate as EmailTemplate}
          handleOk={updateEmailTemplate}
          handleCancel={handleEditTemplateCancel}
        />
      </Row>
    </>
  );
};

export default SecureComponent(EmailTemplatesPage, false);
