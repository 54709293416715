import { ApiUtils } from '../utils/api.utils';
import { UrlConstants } from '../constants/url.constants';
import axios from 'axios';

const getMasterData = (params: any): Promise<any> => {
  return new Promise((resolve, reject) => {
    const config = {
      ...(params ? { params } : {}),
      ...ApiUtils.getHeaders()
    };
    axios
      .get(UrlConstants.GET_MASTER_DATA, config)
      .then(response => {
        if (response.status === 200) resolve(response.data.data);
        else throw response;
      })
      .catch(err => reject(err));
  });
};
const uploadRankObjects = (
  data: any,
  type: any,
  fileName: any,
  sortType: any
): Promise<any> => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        UrlConstants.UPLOAD_RANK_OBJECTS,
        { rankData: data, fileName: fileName, sortType: sortType },
        {
          ...ApiUtils.getHeaders(),
          params: { 'quote-type': type }
        }
      )
      .then(response => {
        if (response.status === 200) resolve(response.data.message);
        else throw response;
      })
      .catch(err => {
        reject(err);
      });
  });
};
const getSampleData = (params: any): Promise<any> => {
  return new Promise((resolve, reject) => {
    const config = {
      ...(params ? { params } : {}),
      ...ApiUtils.getHeaders()
    };
    axios
      .get(UrlConstants.GET_SAMPLE_DATA, config)
      .then(response => {
        if (response.status === 200) resolve(response.data.data);
        else throw response;
      })
      .catch(err => reject(err));
  });
};
const downloadRankObjects = (params: any): Promise<any> => {
  return new Promise((resolve, reject) => {
    const config = {
      ...(params ? { params } : {}),
      ...ApiUtils.getHeaders()
    };
    axios
      .get(UrlConstants.DOWNLOAD_RANK_OBJECT, config)
      .then(response => {
        if (response.status === 200) resolve(response.data.data);
        else throw response;
      })
      .catch(err => {
        reject(err);
      });
  });
};

export const CarrierPriorityService = {
  getMasterData,
  uploadRankObjects,
  getSampleData,
  downloadRankObjects
};
