import { Button, Tooltip } from 'antd';

import type { ColumnsType } from 'antd/lib/table';
import { Config } from '../../types/data/configuration.type';
import { EditOutlined } from '@ant-design/icons';
import { capitalize } from 'lodash';

/**
 * get columns of reports
 * @returns {ColumnsType<Config>}
 */
export const getConfigurationColumns = (
  handleEdit: any,
  disabled = false
): ColumnsType<Config> => [
  {
    title: 'Name',
    dataIndex: 'key',
    key: 'key',
    sorter: (a, b) => a.key.localeCompare(b.key),
    align: 'center'
  },
  {
    title: 'Description',
    dataIndex: 'description',
    key: 'description',
    ellipsis: {
      showTitle: false
    },
    render: description => (
      <Tooltip placement='topLeft' title={description}>
        {description}
      </Tooltip>
    ),
    align: 'center'
  },
  {
    title: 'Value',
    dataIndex: 'value',
    key: 'value',
    sorter: (a, b) => a.value.localeCompare(b.value),
    align: 'center'
  },
  {
    title: 'Type',
    dataIndex: 'type',
    key: 'type',
    sorter: (a, b) => a.type.localeCompare(b.type),
    align: 'center',
    render: type => capitalize(type)
  },
  {
    title: '',
    key: 'id',
    render: data => (
      <Button
        type='primary'
        disabled={disabled}
        onClick={() => handleEdit(data)}>
        <EditOutlined /> Edit
      </Button>
    ),
    align: 'center'
  }
];
