import { Config } from '../../../types/data/configuration.type';
import TableComponent from '../../table';
import { TableType } from '../../../enums/table-type.enum';

type ConfigurationProps = {
  configs?: Config[];
  loading?: boolean;
  disabled?: boolean;
  handleEdit: any;
};

const ConfigurationComponent = (props: ConfigurationProps) => (
  <TableComponent
    handleEdit={props.handleEdit}
    disabled={props.disabled}
    loading={props.loading}
    data={props.configs}
    tableType={TableType.CONFIGURATIONS}
  />
);

export default ConfigurationComponent;
