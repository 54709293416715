import { MSPlanCarrier } from '../../types/data/ms-plan-carrier.type';
import TableComponent from '../table';
import { TableType } from '../../enums/table-type.enum';

type MSPlanCarrierProps = {
  msPLanCarriers: MSPlanCarrier[];
  loading?: boolean;
  handleSelect?: any;
  selectedRows?: React.Key[];
};

export const MSPlanCarrierTableComponent = (props: MSPlanCarrierProps) => (
  <TableComponent
    selection={true}
    handleSelect={props.handleSelect}
    loading={props.loading}
    data={props.msPLanCarriers}
    tableType={TableType.MS_PLAN_CARRIERS}
    selectedRowKeys={props.selectedRows}
  />
);
