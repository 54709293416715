import { Form, FormInstance, Input, Modal } from 'antd';

import React from 'react';
import { SmsTemplate } from '../../../../types/data/smsTemplate.type';

type EditSmsTemplateModalProps = {
  loading: boolean;
  visible: boolean;
  handleOk: (data: any) => void;
  handleCancel: (event: any) => void;
  data: SmsTemplate;
};

const EditSmsTemplateModal = (props: EditSmsTemplateModalProps) => {
  const formModalRef: React.RefObject<FormInstance> =
    React.createRef<FormInstance>();

  const handleFormSubmit = () => {
    formModalRef.current
      ?.validateFields()
      .then(data => {
        const data_: SmsTemplate = {
          key: props.data.key,
          messageBody: data.messageBody,
          includedAttributes: props.data.includedAttributes
        };
        props.handleOk(data_);
      })
      .catch(err => {
        console.log('Form has validation errors', err);
      });
  };

  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 }
  };

  if (props.data)
    return (
      <div>
        <Modal
          title={`Edit SMS template - ${props.data.key}`}
          visible={props.visible}
          onOk={handleFormSubmit}
          onCancel={props.handleCancel}
          okText={'Update template'}
          okButtonProps={{ loading: props.loading }}
          cancelText={'Cancel'}
          cancelButtonProps={{ disabled: props.loading }}
          destroyOnClose={true}>
          <Form
            {...layout}
            ref={formModalRef}
            initialValues={{ ...props.data }}
            style={{ height: '30vh', overflowY: 'auto' }}>
            <Form.Item name='key' label='Name'>
              <Input disabled />
            </Form.Item>
            <Form.Item
              name='messageBody'
              label='Message Body'
              rules={[
                {
                  required: true,
                  message: 'Please provide a valid value'
                }
              ]}>
              <Input.TextArea rows={6} showCount maxLength={1000} />
            </Form.Item>
          </Form>
        </Modal>
      </div>
    );
  else return null;
};

export default EditSmsTemplateModal;
