import { Modal,Button } from 'antd';

type PriorityConfirmationModalProps = {
  visible: boolean;
  handleOk: (data: any) => void;
  handleCancel: (data: any) => void;
};

const PriorityConfirmationModal = (props: PriorityConfirmationModalProps) => (
  <Modal
    centered={true}
    title= 'Priority update request'
    style={{textAlign:'center'}}
    width='30%'
    onOk={props.handleOk}
    footer={null}
    closable={false}
    visible={props.visible}>
    <p>Please confirm if you want to update the priority for the selected rule.</p>
    <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
    <Button type="primary" onClick={props.handleOk}>Yes</Button>
    <Button type="primary" danger onClick={props.handleCancel} style={{ marginLeft: '10px' }}>No</Button>
  </div>
  </Modal>
);

export default PriorityConfirmationModal;