import { Button, Col, Row, Tabs, Tooltip, Typography, message } from 'antd';
import { InfoCircleOutlined, ReloadOutlined } from '@ant-design/icons';
import {
  setSelectedTab,
  setSunfireSwitch
} from '../../store/reducers/config.reducer';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

import { Config } from '../../types/data/configuration.type';
import { ConfigurationCategory } from '../../enums/configuration-category.enum';
import ConfigurationComponent from '../../components/configs/all-configs';
import { ConfigurationService } from '../../service/configuration.service';
import EditConfigModal from '../../modals/configs/update';
import { Helmet } from 'react-helmet';
import { IsAdminProp } from '../../types/props/isAdmin.props';
import { MSPlanCarrier } from '../../types/data/ms-plan-carrier.type';
import { RootState } from '../../store/root.store';
import { RouteConstants } from '../../constants/route.constants';
import SecureComponent from '../../components/authorization';
import { TooltipConstants } from '../../constants/tooltip.constants';
import { useNavigate } from 'react-router-dom';

const ConfigurationsPage: React.FC<IsAdminProp> = () => {
  const [configs, setConfigs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [reloading, setReloading] = useState(false);
  const [editConfigVisible, setEditConfigVisibility] = useState(false);
  const [editConfigLoading, setEditConfigLoading] = useState(false);
  const [selectedConfig, setSelectedConfig] = useState<Config>();
  const selectedTab: number = useSelector(
    (state: RootState) => state.configStore.selectedTab
  );
  const sunfireSwitch: boolean = useSelector(
    (state: RootState) => state.configStore.sunfireSwitch
  );
  const [naicIds, setNaicIds] = useState<string[]>([]);

  const dispatch = useDispatch();
  const handleConfigEditClick = (config: Config) => {
    setEditConfigVisibility(true);
    setSelectedConfig(config);
  };

  const getAllConfigurations = () => {
    setLoading(true);
    const selectedConfigCategory = Object.values(ConfigurationCategory)[
      selectedTab
    ];

    ConfigurationService.getAllConfigs({ category: selectedConfigCategory })
      .then(configs => {
        if (selectedTab === 0) {
          configs.forEach((config: any) => {
            if (config.key === 'SUNFIRE_SWITCH') {
              dispatch(
                setSunfireSwitch(config.value === 'false' ? false : true)
              );
            }
          });
        }
        setConfigs(configs);
      })
      .catch(err => {
        console.log('Error while fetching configs :: ', err);
        message.error('Failed to fetch configs, please try again later');
      })
      .finally(() => setLoading(false));
  };

  const getMSPlanCarriers = () => {
    setLoading(true);
    ConfigurationService.getAllMSCarriers()
      .then((msCarriers: MSPlanCarrier[]) => {
        const naicIds: string[] = msCarriers
          .filter((carrier: MSPlanCarrier) => {
            if (carrier.toQuery) return carrier;
          })
          .map(carrier => carrier.naic);
        setNaicIds(naicIds);
      })
      .catch(err => {
        console.log('Error while fetching carriers :: ', err);
        message.error('Failed to fetch carriers, please try again later');
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    getAllConfigurations();
    getMSPlanCarriers();
  }, [selectedTab]);

  const handleUpdateConfigClick = (data: Config) => {
    setEditConfigLoading(true);
    ConfigurationService.updateConfig(data)
      .then(() => {
        message.success('Configuration Updated Successfully');
      })
      .catch(err => {
        console.log('Error while updating config :: ', err);
        message.error('Failed to updating config, please try again later');
      })
      .finally(() => {
        setEditConfigLoading(false);
        setEditConfigVisibility(false);
        setSelectedConfig(undefined);
        getAllConfigurations();
      });
  };

  const handleAddReportCancel = () => {
    setEditConfigLoading(false);
    setEditConfigVisibility(false);
    setSelectedConfig(undefined);
  };

  const reloadConfigsIntoApp = () => {
    setReloading(true);
    ConfigurationService.reloadConfigsIntoApp()
      .then(message_ => message.success(message_))
      .catch(err => {
        console.log('Error while reloading configs into app :: ', err);
        message.error('Failed to reloading configs, please try again later');
      })
      .finally(() => setReloading(false));
  };

  const navigate = useNavigate();

  const selectCarriers = (key: string) => {
    if (key === 'Medicare Supplement') {
      const config: Config = configs.filter(config => {
        if (config['key'] === 'MS_NAIC_IDs') return config;
      })[0];
      navigate(RouteConstants.MS_PLAN_CARRIERS, {
        state: config
      });
    } else if (key === 'Medicare Advantage') {
      const config: Config = configs.filter(config => {
        if (
          config['key'] ===
          (sunfireSwitch === true
            ? 'SUNFIRE_MA_CONFIGURED_CARRIERS'
            : 'MA_CONFIGURED_CARRIERS')
        )
          return config;
      })[0];
      navigate(RouteConstants.MA_PLAN_CARRIERS, {
        state: config
      });
    } else if (key === 'Medicare Part D') {
      const config: Config = configs.filter(config => {
        if (
          config['key'] ===
          (sunfireSwitch === true
            ? 'SUNFIRE_M_PART_D_CONFIGURED_CARRIERS'
            : 'M_PART_D_CONFIGURED_CARRIERS')
        )
          return config;
      })[0];
      navigate(RouteConstants.M_PART_D_PLAN_CARRIERS, {
        state: config
      });
    }
  };

  const getConfig = (config: never, selectedConfig: Config | undefined) => {
    if (
      config &&
      (config['key'] === 'SUNFIRE_MA_CONFIGURED_CARRIERS' ||
        config['key'] === 'SUNFIRE_M_PART_D_CONFIGURED_CARRIERS' ||
        config['key'] === 'MA_CONFIGURED_CARRIERS' ||
        config['key'] === 'M_PART_D_CONFIGURED_CARRIERS')
    )
      return config;
    else if (config && selectedConfig && config['key'] === selectedConfig.key)
      return config;
  };

  return (
    <>
      <Helmet>
        <title>
          Configurations | Quoting Tool SA Dashboard | Elite Insurance Partners
        </title>
        <meta
          name='description'
          content={`Elite Insurance Partners | Quoting Tool SA Dashboard - Configurations`}
        />
      </Helmet>
      <Row>
        <Col xs={24} className='space-top-10'>
          <Row>
            <Col xs={10}>
              <Typography.Title level={3}>Configurations</Typography.Title>
            </Col>
            <Col xs={14} style={{ textAlign: 'right', alignContent: 'center' }}>
              <Tooltip title='Reload latest config from DB into application'>
                <Button
                  disabled={loading}
                  loading={reloading}
                  onClick={reloadConfigsIntoApp}>
                  {!reloading && <ReloadOutlined />}Reload Configurations
                </Button>
              </Tooltip>
            </Col>
          </Row>
        </Col>

        {/* Edit Configuration Modal */}
        <EditConfigModal
          visible={editConfigVisible}
          loading={editConfigLoading}
          handleOk={handleUpdateConfigClick}
          handleCancel={handleAddReportCancel}
          data={selectedConfig as Config}
          options={configs.filter(config => {
            return getConfig(config, selectedConfig);
          })}
          naicIds={naicIds}
        />
      </Row>
      <Tabs
        defaultActiveKey={selectedTab.toString()}
        className='space-top'
        onChange={e => dispatch(setSelectedTab(parseInt(e)))}>
        {Object.keys(ConfigurationCategory).map((key, index) => (
          <Tabs.TabPane tab={key} key={index}>
            <Row gutter={16} className='space-top-10'>
              <Col xs={12} className='space-top-10'>
                <Typography.Text>
                  <div style={{ display: 'flex', flexDirection: 'row' }}>
                    Configurations related to &nbsp;<b>{key}</b>&nbsp;
                    {key !== 'General' && (
                      <>
                        <Tooltip title={TooltipConstants.GENERAL_DISCLAIMER}>
                          <InfoCircleOutlined
                            style={{ alignSelf: 'center', color: '#00000073' }}
                          />
                        </Tooltip>
                      </>
                    )}
                  </div>
                </Typography.Text>
              </Col>
              {key !== 'General' && (
                <Col
                  xs={12}
                  style={{ textAlign: 'right', alignContent: 'center' }}>
                  <Button onClick={() => selectCarriers(key)}>
                    Select Carriers
                  </Button>
                </Col>
              )}
            </Row>
            <Row gutter={16} className='space-top-10'>
              <Col xs={24} className='space-top-10'>
                <ConfigurationComponent
                  loading={loading}
                  configs={configs.filter(config => {
                    if (
                      ![
                        'MS_NAIC_IDs',
                        'MA_CONFIGURED_CARRIERS',
                        'M_PART_D_CONFIGURED_CARRIERS',
                        'SUNFIRE_MA_CONFIGURED_CARRIERS',
                        'SUNFIRE_M_PART_D_CONFIGURED_CARRIERS'
                      ].includes(config['key'])
                    )
                      return config;
                  })}
                  disabled={reloading}
                  handleEdit={handleConfigEditClick}
                />
              </Col>
            </Row>
          </Tabs.TabPane>
        ))}
      </Tabs>
    </>
  );
};

export default SecureComponent(ConfigurationsPage, false);
