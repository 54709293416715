import axios from 'axios';
import axiosRetry from 'axios-retry';
const pattern = new RegExp(/\b\d{5}\b/);
const isValidZipCode = (zipCode: string) => {
  if (zipCode) return zipCode.length === 5 && pattern.test(zipCode);
  return false;
};
const getCountyForZip = (zipCode: string) => {
  return new Promise((resolve, reject) => {
    if (zipCode) {
      axiosRetry(axios, { retries: 3 });
      axios
        .get(
          `https://us-zipcode.api.smartystreets.com/lookup?zipcode=${zipCode}&key=27259743547683152`
        )
        .then(response => {
          if (response.data[0].status !== 'invalid_zipcode') {
            resolve(response.data[0]);
          } else throw response;
        })
        .catch(error => {
          console.error('Invalid Zipcode ', error.data);
          reject(error);
        });
    } else reject('zipCode is empty');
  });
};
const ZipCodeService = {
  isValidZipCode,
  getCountyForZip
};
export default ZipCodeService;
