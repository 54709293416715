import { Button, Tooltip } from 'antd';

import type { ColumnsType } from 'antd/lib/table';
import { EditOutlined } from '@ant-design/icons';
import { EmailTemplate } from '../../types/data/emailTemplate.type';
import { SmsTemplate } from '../../types/data/smsTemplate.type';

/**
 * get columns of Email Templates
 * @returns {ColumnsType<EmailTemplate>}
 */
export const getEmailTemplateColumns = (
  handleEdit: any
): ColumnsType<EmailTemplate> => [
  {
    title: 'Name',
    dataIndex: 'key',
    key: 'key',
    sorter: (a, b) => a.key.localeCompare(b.key),
    align: 'center'
  },
  {
    title: 'Email Body',
    dataIndex: 'emailBody',
    key: 'emailBody',
    ellipsis: {
      showTitle: false
    },
    render: emailBody => (
      <Tooltip placement='topLeft' title={emailBody}>
        {emailBody}
      </Tooltip>
    ),
    align: 'center'
  },
  {
    title: 'Subject',
    dataIndex: 'emailSubject',
    key: 'emailSubject',
    align: 'center'
  },
  {
    title: '',
    key: 'id',
    render: data => (
      <Button type='primary' onClick={() => handleEdit(data)}>
        <EditOutlined /> Edit
      </Button>
    ),
    align: 'center'
  }
];

/**
 * get columns of SMS Templates
 * @returns {ColumnsType<SmsTemplate>}
 */
export const getSmsTemplateColumns = (
  handleEdit: any
): ColumnsType<SmsTemplate> => [
  {
    title: 'Name',
    dataIndex: 'key',
    key: 'key',
    sorter: (a, b) => a.key.localeCompare(b.key),
    align: 'center'
  },
  {
    title: 'Message Body',
    dataIndex: 'messageBody',
    key: 'messageBody',
    ellipsis: {
      showTitle: false
    },
    render: messageBody => (
      <Tooltip placement='topLeft' title={messageBody}>
        {messageBody}
      </Tooltip>
    ),
    align: 'center'
  },
  {
    title: '',
    key: 'id',
    render: data => (
      <Button type='primary' onClick={() => handleEdit(data)}>
        <EditOutlined /> Edit
      </Button>
    ),
    align: 'center'
  }
];
