export const TooltipConstants = {
  GENERAL_DISCLAIMER:
    'The sequence of the plans that are presented to clients is determined by the sequence of the default carriers chosen. If boosting of carriers is configured for the plan type, the sequence will be superseded by the boosting.',
  DOWNLOAD_RANK_OBJECT: 'Click to download the current rank object uploaded',
  DOWNLOAD_TEMPLATE:
    'Click to download the rank object template that can be edited and uploaded to update the rank object',
  DOWNLOAD_MASTER_DATA:
    'Click to download the master data for every attribute part of the rank object',
  QUOTE_TYPE:
    'Select the Plan Type for which you want to upload the rank object',
  SORT_TYPE:
    'Select the Sort type for which you want to upload the rank object',
  UPLOAD_RANK_OBJECT:
    'Upload the rank object CSV. Please download and use the template to abide to the structure'
};
