import { PayloadAction, createSlice } from '@reduxjs/toolkit';

const initialState: {
  selectedTab: number;
  sunfireSwitch: boolean;
} = { selectedTab: 0, sunfireSwitch: false };

export const configSlice = createSlice({
  name: 'Config',
  initialState: initialState,
  reducers: {
    // Redux Toolkit allows us to write "mutating" logic in reducers. It
    // doesn't actually mutate the state because it uses the Immer library,
    // which detects changes to a "draft state" and produces a brand new
    // immutable state based off those changes
    setSelectedTab: (state, action: PayloadAction<number>) => {
      state.selectedTab = action.payload;
    },
    getSelectedTab: (state, action: PayloadAction<number>) => {
      state.selectedTab = action.payload;
    },
    setSunfireSwitch: (state, action: PayloadAction<boolean>) => {
      state.sunfireSwitch = action.payload;
    }
  }
});

// Action creators are generated for each case reducer function
export const { setSelectedTab, getSelectedTab, setSunfireSwitch } =
  configSlice.actions;

export default configSlice.reducer;
