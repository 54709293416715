import { MNSPlanCarrier } from '../../types/data/mns-plan-carrier.type';
import TableComponent from '../table';
import { TableType } from '../../enums/table-type.enum';

type MNSPlanCarrierProps = {
  mnsPLanCarriers: MNSPlanCarrier[];
  loading?: boolean;
  handleSelect?: any;
  selectedRows?: React.Key[];
};

export const MNSPlanCarrierTableComponent = (props: MNSPlanCarrierProps) => (
  <TableComponent
    selection={true}
    handleSelect={props.handleSelect}
    loading={props.loading}
    data={props.mnsPLanCarriers}
    tableType={TableType.MNS_PLAN_CARRIERS}
    selectedRowKeys={props.selectedRows}
  />
);
