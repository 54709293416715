export enum MSPlanTypes {
  A = 'A',
  B = 'B',
  C = 'C',
  D = 'D',
  F = 'F',
  G = 'G',
  HDF = 'HDF',
  HDG = 'HDG',
  K = 'K',
  L = 'L',
  M = 'M',
  N = 'N',
  WI_BASE = 'WI_BASE',
  WI_HDED = 'WI_HDED',
  WI_50 = 'WI_50',
  WI_75 = 'WI_75',
  MN_BASIC = 'MN_BASIC',
  MN_EXTB = 'MN_EXTB',
  MN_HDED = 'MN_HDED',
  MN_HDED2 = 'MN_HDED2',
  MN_PBCO = 'MN_PBCO',
  MN_XBAS = 'MN_XBAS',
  MN_XBAS2 = 'MN_XBAS2',
  MN_50 = 'MN_50',
  MN_A50 = 'MN_A50',
  MN_75 = 'MN_75',
  MA_CORE = 'MA_CORE',
  MA_SUPP1 = 'MA_SUPP1',
  MA_SUPP1A = 'MA_SUPP1A'
}
