export const StringConstants = {
  COOKIE: {
    JWT: 'jwt',
    USER: 'user'
  },
  REPORT_FREQUENCY: [
    {
      FREQUENCY: '5',
      VALUE: 'Every 5 minutes'
    },
    {
      FREQUENCY: '15',
      VALUE: 'Every 15 minutes'
    },
    {
      FREQUENCY: '30',
      VALUE: 'Every 30 minutes'
    },
    {
      FREQUENCY: '60',
      VALUE: 'Every 60 minutes'
    },
    {
      FREQUENCY: '1440',
      VALUE: 'Once a day'
    }
  ],
  OPERATIONS: [
    {
      value: 'contains',
      label: 'Contains'
    },
    {
      value: 'does_not_contain',
      label: 'Does not contain'
    },
    {
      value: 'contains_cs',
      label: 'Contains (Case-sensitive)'
    },
    {
      value: 'does_not_contains_cs',
      label: 'Does not contains (Case-sensitive)'
    },
    {
      value: 'equals_cs',
      label: 'Equals (Case-sensitive)'
    },
    {
      value: 'starts_with',
      label: 'Starts with'
    }
  ]
};
