import { LeadManagementType } from '../../types/data/lead-management.type';
import TableComponent from '../table';
import { TableType } from '../../enums/table-type.enum';

type ConfigurationProps = {
  data?: LeadManagementType[];
  loading?: boolean;
  disabled?: boolean;
  handleEdit?: any;
  handleRemove?: any;
  handleDragAndDrop: any;
  isDataFetching?: boolean
};

const LeadManagementComponent = (props: ConfigurationProps) => {
  return (
    <TableComponent
      pagination={false}
      handleEdit={props.handleEdit}
      handleRemove={props.handleRemove}
      disabled={props.disabled}
      loading={props.loading}
      data={props.data}
      tableType={TableType.LEAD_MANAGEMENT}
      handleDragAndDrop={props.handleDragAndDrop}
      isDataFetching={props.isDataFetching}
    />
  );
};

export default LeadManagementComponent;
