import 'antd/dist/reset.css';
import './App.css';
import { message } from 'antd';

import {
  CarrierPriority,
  Configurations,
  EmailTemplates,
  Login,
  MaPlanCarriers,
  MpartDPlanCarriers,
  MsPlanCarriers,
  Redirect,
  SmsTemplates,
  LeadManagement
} from './pages';
import { Route, Routes } from 'react-router-dom';

import { RouteConstants } from './constants/route.constants';
import axios from 'axios';

const App = () => {

  axios.interceptors.response.use(
    function (response) {
      return response;
    }, async function (error) {
      if (error.response.status === 401 && window.location.pathname !== '/redirect') {
        await message.error('Auth Token expired. Refreshing the page')
        window.location.href = window.location.origin + '/redirect'
      }
      return Promise.reject(error);
    });

  return (
    <Routes>
      <Route path={RouteConstants.HOME} element={<Login />} />
      <Route path={RouteConstants.LOGIN} element={<Login />} />
      <Route
        path={RouteConstants.CARRIER_PRIORITY}
        element={<CarrierPriority />}
      />
      <Route path={RouteConstants.REDIRECT} element={<Redirect />} />
      <Route
        path={RouteConstants.CONFIGURATIONS}
        element={<Configurations />}
      />
      <Route path={RouteConstants.SMS_TEMPLATES} element={<SmsTemplates />} />
      <Route
        path={RouteConstants.EMAIL_TEMPLATES}
        element={<EmailTemplates />}
      />
      <Route
        path={RouteConstants.LEAD_MANAGEMENT}
        element={<LeadManagement />}
        />
      <Route
        path={RouteConstants.MS_PLAN_CARRIERS}
        element={<MsPlanCarriers />}
      />
      <Route
        path={RouteConstants.MA_PLAN_CARRIERS}
        element={<MaPlanCarriers />}
      />
      <Route
        path={RouteConstants.M_PART_D_PLAN_CARRIERS}
        element={<MpartDPlanCarriers />}
      />
    </Routes>
  );
};

export default App;
