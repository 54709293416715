import { Footer, Header } from '../../components';
import { Layout, LayoutProps } from 'antd';

import { Content } from 'antd/lib/layout/layout';
import { IsAdminProp } from '../../types/props/isAdmin.props';
import React from 'react';

const LayoutPage: React.FC<LayoutProps & IsAdminProp> = ({
  children,
  isAdmin
}) => (
  <Layout>
    <Header isAdmin={isAdmin} />
    <Content className='site-layout main-content'>
      <div
        className='site-content-background'
        style={{ padding: 24, minHeight: 380 }}>
        {children}
      </div>
    </Content>
    <Footer />
  </Layout>
);

export default LayoutPage;
