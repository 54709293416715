import { ApiUtils } from '../utils/api.utils';
import { Config } from '../types/data/configuration.type';
import { ConfigurationCategory } from '../enums/configuration-category.enum';
import { MedicareQuoteType } from '../enums/medicare-quote-type.enum';
import { UrlConstants } from '../constants/url.constants';
import axios from 'axios';

type GetAllConfigParams = {
  category: ConfigurationCategory;
};

type GetAllMNSCarriers = {
  'quote-type': MedicareQuoteType;
};

const getAllConfigs = (params: GetAllConfigParams): Promise<any> => {
  return new Promise((resolve, reject) => {
    const config = {
      ...(params ? { params } : {}),
      ...ApiUtils.getHeaders()
    };

    axios
      .get(UrlConstants.GET_ALL_CONFIGURATIONS, config)
      .then(response => {
        if (response.status === 200) resolve(response.data.data);
        else throw response;
      })
      .catch(err => reject(err));
  });
};

const updateConfig = (data: Config): Promise<any> => {
  return new Promise((resolve, reject) => {
    const url = UrlConstants.UPDATE_CONFIG_BY_ID.replaceAll(
      ':configId',
      data.id
    );
    axios
      .patch(
        url,
        { description: data.description, key: data.key, value: data.value },
        ApiUtils.getHeaders()
      )
      .then(response => {
        if (response.status === 200) resolve(response.data.data);
        else throw response;
      })
      .catch(err => reject(err));
  });
};

const reloadConfigsIntoApp = (): Promise<any> => {
  return new Promise((resolve, reject) => {
    axios
      .post(UrlConstants.REFRESH_CONFIGURATIONS, {}, ApiUtils.getHeaders())
      .then(response => {
        if (response.status === 200) resolve(response.data.message);
        else throw response;
      })
      .catch(err => reject(err));
  });
};

const getAllMSCarriers = (): Promise<any> => {
  return new Promise((resolve, reject) => {
    axios
      .get(UrlConstants.GET_CARRIERS_OF_MS_PLANS, ApiUtils.getHeaders())
      .then(response => {
        if (response.status === 200) resolve(response.data.data);
        else throw response;
      })
      .catch(err => reject(err));
  });
};

const updateMSCompanies = async (data: Config): Promise<any> => {
  return new Promise((resolve, reject) => {
    const url = UrlConstants.UPDATE_CARRIERS_OF_MS_PLANS.replaceAll(
      ':configId',
      data.id
    );
    axios
      .patch(
        url,
        {
          description: data.description,
          value: data.value
        },
        ApiUtils.getHeaders()
      )
      .then(response => {
        if (response.status === 200) resolve(response.data.data);
        else throw response;
      })
      .catch(err => reject(err));
  });
};

const getAllMNSCarriers = (params: GetAllMNSCarriers): Promise<any> => {
  return new Promise((resolve, reject) => {
    const config = {
      ...(params ? { params } : {}),
      ...ApiUtils.getHeaders()
    };
    axios
      .get(UrlConstants.GET_CARRIERS_OF_MNS_PLANS, config)
      .then(response => {
        if (response.status === 200) resolve(response.data.data);
        else throw response;
      })
      .catch(err => reject(err));
  });
};

const updateMNSCarrierConfigs = (
  data: string[],
  quoteType: string,
  configId: string
): Promise<any> => {
  return new Promise((resolve, reject) => {
    const url = UrlConstants.UPDATE_CARRIERS_OF_MNS_PLANS.replaceAll(
      ':configId',
      configId
    );
    axios
      .patch(
        url,
        {
          ids: data,
          quoteType
        },
        ApiUtils.getHeaders()
      )
      .then(response => {
        if (response.status === 200) resolve(response.data.data);
        else throw response;
      })
      .catch(err => reject(err));
  });
};

export const ConfigurationService = {
  getAllConfigs,
  updateConfig,
  reloadConfigsIntoApp,
  getAllMSCarriers,
  updateMSCompanies,
  getAllMNSCarriers,
  updateMNSCarrierConfigs
};
