import { EmailTemplate } from '../../../types/data/emailTemplate.type';
import TableComponent from '../../table';
import { TableType } from '../../../enums/table-type.enum';

type TemplateProps = {
  type: TableType;
  templates?: EmailTemplate[];
  loading?: boolean;
  handleEdit?: any;
};

const TemplatesComponent = (props: TemplateProps) => (
  <TableComponent
    loading={props.loading}
    data={props.templates}
    tableType={props.type}
    handleEdit={props.handleEdit}
  />
);

export default TemplatesComponent;
