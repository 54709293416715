import './index.css';

import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { MsalProvider } from '@azure/msal-react';
import { Provider } from 'react-redux';
import { PublicClientApplication } from '@azure/msal-browser';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { msalConfiguration } from './configurations/msal.configuration';
import rootStore from './store/root.store';

const msalInstance: PublicClientApplication = new PublicClientApplication(
  msalConfiguration
);

const root = createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <React.StrictMode>
    <MsalProvider instance={msalInstance}>
      <Provider store={rootStore}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </Provider>
    </MsalProvider>
  </React.StrictMode>
);
