import { ApiUtils } from '../utils/api.utils';
import { EmailTemplate } from '../types/data/emailTemplate.type';
import { SmsTemplate } from '../types/data/smsTemplate.type';
import { UrlConstants } from '../constants/url.constants';
import axios from 'axios';

const getAllEmailTemplates = (): Promise<any> => {
  return new Promise((resolve, reject) =>
    axios
      .get(UrlConstants.GET_ALL_EMAIL_TEMPLATES, ApiUtils.getHeaders())
      .then(response => {
        if (response.status === 200) resolve(response.data.data);
        else throw response;
      })
      .catch(err => reject(err))
  );
};

const getAllSmsTemplates = (): Promise<any> => {
  return new Promise((resolve, reject) =>
    axios
      .get(UrlConstants.GET_ALL_SMS_TEMPLATES, ApiUtils.getHeaders())
      .then(response => {
        if (response.status === 200) resolve(response.data.data);
        else throw response;
      })
      .catch(err => reject(err))
  );
};

const updateSmsTemplate = (data: SmsTemplate): Promise<any> => {
  return new Promise((resolve, reject) => {
    axios
      .patch(UrlConstants.UPDATE_SMS_TEMPLATE, data, ApiUtils.getHeaders())
      .then(response => {
        if (response.status === 200) resolve(response.data.data);
        else throw response;
      })
      .catch(err => reject(err));
  });
};

const updateEmailTemplate = (data: EmailTemplate): Promise<any> => {
  return new Promise((resolve, reject) => {
    axios
      .patch(UrlConstants.UPDATE_EMAIL_TEMPLATE, data, ApiUtils.getHeaders())
      .then(response => {
        if (response.status === 200) resolve(response.data.data);
        else throw response;
      })
      .catch(err => reject(err));
  });
};

export const TemplateService = {
  getAllEmailTemplates,
  getAllSmsTemplates,
  updateEmailTemplate,
  updateSmsTemplate
};
